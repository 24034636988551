import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Theme } from "../misc/Option";
import Select from "react-select";
import TabbilyApi from "../api";
import Alert from "../common/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faPlus } from "@fortawesome/free-solid-svg-icons";

function AddEditEventForm({ currentName = "", group = null, eventId = null, closeModal = null, refetchData }) {
  const [selectedOption, setSelectedOption] = useState(group);
  const [name, setName] = useState(currentName);
  const [groupsList, setGroupsList] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const navigate = useNavigate();

  useEffect(
    function getOptionsList() {
      async function getGroups() {
        const userGroupsList = await TabbilyApi.getAllGroups();
        setGroupsList(userGroupsList.map((g) => ({ value: g.id, label: g.name })));
      }

      if (!group) getGroups();
    }, [group]);

  async function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    try {
      if (!currentName) {
        const groupId = selectedOption.value;
        const event = await TabbilyApi.createEvent(name, groupId);
        navigate(`/events/${event.id}`);
      } else {
        await TabbilyApi.updateEvent(name, eventId);
      }

      closeModal();
      refetchData();
    } catch (err) {
      setFormErrors(["Something went wrong with this request."]);
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="name">
            Name
          </Label>
          <Input
            id="name"
            name="name"
            placeholder="Name"
            value={name}
            type="text"
            onChange={(evt) => setName(evt.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="selectGroup">
            Select Group
          </Label>
          <Select
            id="selectGroup"
            options={groupsList}
            defaultValue={selectedOption}
            placeholder="Select group"
            onChange={setSelectedOption}
            required
            isDisabled={group}
            theme={Theme}
          />
        </FormGroup>
        {formErrors.length ? (
          <Alert type="danger" messages={formErrors} />
        ) : null}
        <div className="d-flex flex-row justify-content-end">
          <Button type="submit" color="primary" className="text-dark">
          <FontAwesomeIcon icon={currentName ? faFloppyDisk : faPlus} /> {currentName ? "Save Event" : "Create Event"}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddEditEventForm;