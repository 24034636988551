import { Input, Button, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import "./expense.css";

function SplitContributionForm({ participants, formData, handleContributionBlur, handleChange, handleRemoveParticipant, toggleLock }) {

  return (
    <>
      {participants.map((p, idx: number) => (
        <Row key={`${p.value}${idx}${p.label}`} className="d-flex align-items-center justify-content-between my-2 p-0" >
          <Col xs={4} className="flex-grow-1">
            <div className="d-flex flex-column p-0 ">
              {p.label}
              <small>{p.subLabel}</small>
            </div>
          </Col>
          <Col xs={4} md={3} className="p-sm-0 p-md-1">
            <div className="input-icon">
              <i>$</i>
              <Input
                id="dollar"
                name="dollar"
                placeholder="0.00"
                type="text"
                inputMode="decimal"
                min="0"
                value={formData.contributions[idx].dollar}
                onBlur={(evt) => handleContributionBlur(idx, evt, 2)}
                onChange={(evt) => handleChange(evt, idx)}
              />
            </div>
          </Col>
          <Col xs={3} md={2} className="p-0 mx-md-3">
            <div className="input-icon input-icon-right">
              <Input
                id="percent"
                name="percent"
                placeholder="0"
                type="number"
                step="0.01"
                min="0"
                value={formData.contributions[idx].percent}
                onBlur={(evt) => handleContributionBlur(idx, evt, 1)}
                onChange={(evt) => handleChange(evt, idx)}
              />
              <i>%</i>
            </div>
          </Col>
          <Col xs={1} md={1} className="p-2">
            {/* <Button  onClick={() => toggleLock(p.value)} className="small-delete mx-1">
              <FontAwesomeIcon icon={formData.locked ? faLockOpen : faLock } />
            </Button> */}
            <Button  onClick={() => handleRemoveParticipant(idx)} className="small-delete">
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>
          </Col>
        </Row>
      ))}
    </>
  );
}

export default SplitContributionForm;