/** Presentational component for showing bootstrap-style alerts.
 *
 * { LoginForm, SignupForm, ProfileForm } -> Alert
 **/

function CheckEmail() {

  return (
    <div>
      Please check your email for instructions on how to reset your password.
    </div>
  );
}

export default CheckEmail;
