import { Card, CardBody, CardTitle, CardText, NavLink, CardImg } from "reactstrap";

/** LinkCard Component
 *
 * Props: listing {id, userId, photo, price, details}
 * State: None
 */

function LinkCard({ type, info }) {
  return (
    <Card className="col-9 col-sm-6 m-2 text-dark" color="info"
      >
      <NavLink href={`/${type}s/${info.id}`}>
        <CardBody className="p-0">
          <div className="LinkCard">
            <CardTitle tag="h6" className="m-2">{info.name}</CardTitle>
          </div>
        </CardBody>
      </NavLink>
    </Card>
  );
}

export default LinkCard;
