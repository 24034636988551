import { useState, useContext } from "react";

import UserContext from "../UserContext";

import { useParams, Navigate, useNavigate } from "react-router-dom";
import { Button, List, UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";

import TabbilyApi from "../api";

import Confirmation from "../components/Confirmation";
import { useModals } from "../utils/useModal";
import { useFetch } from "../utils/useFetch";
import CustomModal from "../components/CustomModal";
import EventList from "../event/EventList";
import LoadingSpinner from "../common/LoadingSpinner";
import NotFound from "../common/NotFound";
import ServerError from "../common/ServerError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function GroupDetailPage() {

  const { currentUser } = useContext(UserContext);
  const { groupId } = useParams();

  const navigate = useNavigate();

  const [groupIsLoading, groupInfo, groupError, refetchGroup] = useFetch("getGroup", groupId);
  const [eventsIsLoading, events, eventsError] = useFetch(groupInfo ? "getAllEvents" : null);

  const [groupFormIsOpen, closeGroupFormModal, toggleGroupForm] = useModals('group');
  const [eventFormIsOpen, closeEventFormModal, toggleEventForm] = useModals('event');

  const [deleteGroupConfirm, setDeleteGroupConfirm] = useState(false);
  const confirmToggle = () => setDeleteGroupConfirm(!deleteGroupConfirm);

  async function deleteGroup() {
    await TabbilyApi.deleteGroup(groupId);
    navigate("/groups");
  }

  if (!currentUser) return <Navigate to="/" />;

  if (groupError) return <NotFound />;

  if (eventsError) return <ServerError />;

  if (groupIsLoading || eventsIsLoading) return <LoadingSpinner />;

  return (
    <>
      {!currentUser && <Navigate to="/" />}
      {(!groupIsLoading && !eventsIsLoading) &&
        <>
          <h3>{groupInfo.name}</h3>
          <div className="d-flex justify-content-center">
            <UncontrolledAccordion stayOpen className="col-9 col-sm-6 m-2 my-4">
              <AccordionItem>
                <AccordionHeader targetId="1">
                  Group Details
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <h6 className="text-dark">Members</h6>
                  <List type="unstyled" className="text-dark">
                    {groupInfo.members.map((m) => (
                      <li key={`${m.handle}+${m.displayName}`}>{m.displayName} <small>{m.handle ? `@${m.handle}` : "(Invited)"}</small></li>
                    ))}
                  </List>
                  <Button onClick={confirmToggle} size="sm"><FontAwesomeIcon icon={faTrashCan} /> Delete Group</Button>
                  <Button onClick={toggleGroupForm} color="primary" className="text-dark mx-2" size="sm">
                    <FontAwesomeIcon icon={faPenToSquare} /> Edit Group</Button>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>

          </div>
          <CustomModal
            type="group"
            title="Edit Group"
            isOpen={groupFormIsOpen}
            toggle={toggleGroupForm}
            edit={true}
            data={{
              currentName: groupInfo.name,
              currentMembers: groupInfo.members
                .filter(m => m.id !== currentUser.userId)
                .map(m => ({ value: m.id, label: m.displayName, subLabel: m.handle })),
              groupId
            }}
            closeModal={closeGroupFormModal}
            refetchData={refetchGroup}
          />

          <Confirmation
            isOpen={deleteGroupConfirm}
            toggle={confirmToggle}
            confirm={deleteGroup}
            cancel={() => setDeleteGroupConfirm(false)}
            message="Are you sure you want to delete this group? All associated events and expenses will be lost." />
          <h5>Recent Events</h5>
          <Button onClick={toggleEventForm} className="btn my-2" color="primary" outline>
            <FontAwesomeIcon icon={faPlus} /> New Event
          </Button>
          <EventList events={events} groupId={groupId} />
          <CustomModal
            type="event"
            title="Create New Event"
            isOpen={eventFormIsOpen}
            toggle={toggleEventForm}
            data={{ group: { value: groupId, label: groupInfo.name } }}
            closeModal={closeEventFormModal}
          />
        </>}
    </>
  );
}

export default GroupDetailPage;