import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";

/** LinkCard Component
 *
 * Props: listing {id, userId, photo, price, details}
 * State: None
 */

function SplitCard({ split }) {
  return (

    <Card className="my-1" color="light" >
      <CardBody className="px-2 py-1">
        <Row>
          <Col className="d-flex flex-column text-start">
            <CardTitle className="m-0" style={{ fontSize: "14px" }}>{split.displayName}</CardTitle>
            <small>{`${split.handle ? "@" : ""}${split.handle || "(Invited)"}`}</small>
          </Col>
          <Col className="d-flex flex-column justify-content-end align-items-end col-3">
            <h6 className="m-0">${split.dollar}</h6>
            <small>{split.percent} %</small>
          </Col>

        </Row>
      </CardBody>
    </Card>
  );
}

export default SplitCard;
