import axios from "axios";

import { SignupInterface, LoginInterface, EditUserInterface } from "./typing/interfaces";
import { ContributionInterface, ItemInterface } from "./expense/AddExpenseForm";

axios.defaults.withCredentials = true;

const HOSTNAME = window.location.hostname;
const BASE_URL = HOSTNAME === process.env.REACT_APP_BASE_URL ?
  `https://api.${HOSTNAME}` :
  `https://api-${HOSTNAME === "localhost" ?
    `dev.${process.env.REACT_APP_BASE_URL}` :
    HOSTNAME}`;

class TabbilyApi {

  static token: string;

  static async request(endpoint: string, data = {}, method = "GET") {
    // console.debug("API Call:", endpoint, data, method);

    const url = `${BASE_URL}/${endpoint}`;
    //const headers = { Authorization: `Bearer ${TabbilyApi.token}` };
    const params = method === "GET" ? data : {};

    try {
      //return (await axios({ url, method, data, params, headers })).data;
      return (await axios({ url, method, data, params })).data;
    } catch (err) {
      // console.error("API Error:", err.response);
      let message = err.response.data.message;
      throw Array.isArray(message) ? message : [message];
    }
  }

  //////////////////////////////// USER /////////////////////////////////////

  static async signup(signupData: SignupInterface) {
    const data = {
      handle: signupData.username,
      display_name: signupData.displayName,
      email: signupData.email,
      new_password: signupData.password,
      invitation_code: signupData.invitationCode || null,
    };

    const response = await this.request(`users`, data, "POST");
    return response.user;
  }

  static async login(loginData: LoginInterface) {
    const response = await axios.post(`${BASE_URL}/token`, {}, {
      auth: {
        username: loginData.usernameOrEmail,
        password: loginData.password
      }
    });

    return response.data.token;
  }

  static async logout() {
    const response = await axios.delete(`${BASE_URL}/token`);
    return response.status === 204;
  }

  static async getCurrentUser() {
    const response = await this.request(`users/me`);
    return {
      userId: response.user_id,
      displayName: response.display_name,
      handle: response.handle,
      email: response.email,
    };
  }

  static async updateUser(updateData: EditUserInterface) {

    type EditUser = {
      handle: string,
      display_name: string,
      email: string,
      old_password?: string,
      new_password?: string,
    };

    const data: EditUser = {
      handle: updateData.username,
      display_name: updateData.displayName,
      email: updateData.email,
      old_password: updateData.password
    };

    if (updateData.newPassword) data.new_password = updateData.newPassword;

    await this.request(`account`, data, "PUT");
  }

  static async getAllUsers() {
    const response = await this.request(`users`);
    return response.users.map((u) => (
      {
        userId: u.user_id,
        displayName: u.display_name,
        handle: u.handle,
        email: u.email,
        avatar: u._links.avatar
      }));
  }

  static async inviteUser(name: string, email: string) {
    await this.request(`invitations`, { display_name: name, email }, "POST");
  }

  static async getInvitedUser(token: string) {
    const response = await this.request(`invitations/${token}`);
    return { displayName: response.display_name, email: response.email };
  }

  static async resetPassword(usernameOrEmail: string) {
    await this.request('account/passwordReset', { user_id: usernameOrEmail }, "POST");
  }

  static async changePassword(token: string, newPassword: string) {
    await this.request('account/passwordReset', { token, new_password: newPassword }, "PUT");
  }

  //////////////////////////////// GROUP /////////////////////////////////////

  static async getAllGroups() {
    const response = await this.request(`groups`);
    return response.groups.map((g) => ({ id: g.id, name: g.name })).reverse();
  }

  static async getGroup(groupId: string) {
    const response = await this.request(`groups/${groupId}`);

    const members = response.members.map((m) => ({
      displayName: m.display_name,
      handle: m.handle,
      id: m.user_id,
      avatar: m._links.avatar
    }));

    response.members = members;
    return response;
  }

  static async createGroup(name: string, userIds: number[] | null) {
    const response = await this.request(`groups`, { name }, "POST");
    const groupId = response.id;

    if (userIds) await this.addGroupMembers(userIds, groupId);

    return response;
  }

  static async updateGroup(name: string, groupId: string) {
    const response = await this.request(`groups/${groupId}`, { name }, "PUT");
    return response;
  }

  static async addGroupMembers(userIds: number[], groupId: number) {
    const response = await this.request(`groups/${groupId}/members`, { user_ids: userIds }, "POST");
    return response;
  }

  static async removeGroupMembers(userId: number, groupId: number) {
    const response = await this.request(`groups/${groupId}/members`, { user_id: userId }, "DELETE");
    return response;
  }

  static async deleteGroup(groupId: string) {
    const response = await this.request(`groups/${groupId}`, {}, "DELETE");
    return response;
  }

  //////////////////////////////// EVENT /////////////////////////////////////

  static async getAllEvents() {
    const response = await this.request(`events`);
    return response.events.map((e) => ({ id: e.id, name: e.name, groupId: e.group_id })).reverse();
  }

  static async getEvent(eventId: string) {
    const response = await this.request(`events/${eventId}`);
    return {
      id: response.id,
      groupId: response.group_id,
      name: response.name,
      total: response.total,
      expectedPayments: response.expected_payments?.map(p => ({
        amount: p.amount,
        eventId: p.event_id,
        id: p.id,
        payee: {
          displayName: p.payee.display_name,
          handle: p.payee.handle,
          userId: p.payee.user_id
        },
        payer: {
          displayName: p.payer.display_name,
          handle: p.payer.handle,
          userId: p.payer.user_id
        },
        status: p.status
      })) || null,
    };
  }

  static async createEvent(name: string, groupId: string) {
    const response = await this.request(`events`, { name, group_id: groupId }, "POST");
    return response;
  }

  static async updateEvent(name: string, eventId: string) {
    const response = await this.request(`events/${eventId}`, { name }, "PUT");
    return response;
  }

  static async deleteEvent(eventId: string) {
    const response = await this.request(`events/${eventId}`, {}, "DELETE");
    return response;
  }

  //////////////////////////////// EXPENSE /////////////////////////////////////

  static async getAllExpenses(eventId: string) {
    const response = await this.request(`events/${eventId}/expenses`);

    return response.expenses.map((e) => ({
      id: e.id,
      eventId: e.event_id,
      name: e.name,
      amount: e.amount,
      paidBy: {
        handle: e.paid_by.handle,
        displayName: e.paid_by.display_name
      },
      date: e.date,
      fullyAllocated: e.fully_allocated,
      splitType: e.split_type,
    }));
  }

  static async getExpense(eventId: string, expenseId: string) {
    const response = await this.request(`events/${eventId}/expenses/${expenseId}`);
    return {
      expenseId: response.id,
      eventId: response.event_id,
      name: response.name,
      amount: response.amount,
      paidBy: {
        userId: response.paid_by.user_id,
        displayName: response.paid_by.display_name,
        handle: response.paid_by.handle,
      },
      date: response.date,
      fullyAllocated: response.fully_allocated,
      notes: response.notes,
      splitType: response.split_type
    };
  }

  static async createExpense(expenseData, eventId: string) {
    const data = {
      name: expenseData.name,
      amount: expenseData.amount,
      paid_by: expenseData.paidBy.value
    };

    const response = await this.request(`events/${eventId}/expenses`, data, "POST");
    return response;
  }

  static async updateExpense(expenseData, eventId: string, expenseId: string) {
    const data = {
      name: expenseData.name,
      amount: expenseData.amount,
      paid_by: expenseData.paidBy.value
    };

    const response = await this.request(`events/${eventId}/expenses/${expenseId}`, data, "PUT");

    return {
      name: response.name,
      amount: response.amount,
      paidBy: {
        userId: response.paid_by.user_id,
        displayName: response.paid_by.display_name,
        handle: response.paid_by.handle,
      },
      date: response.date,
      fullyAllocated: response.fully_allocated,
      notes: response.notes,
      splitType: response.split_type
    };
    // await this.updateSplits(expenseData.contributions, expenseData.items, eventId, expenseId);
  }

  static async deleteExpense(eventId: string, expenseId: string) {
    const response = await this.request(`events/${eventId}/expenses/${expenseId}`, {}, "DELETE");
    return response;
  }

  static async settleExpenses(eventId: string, force = false) {
    const response = await this.request(`events/${eventId}/settle`, { force }, "POST");

    return response.expected_payments.map(p => ({
      amount: p.amount,
      eventId: p.event_id,
      id: p.id,
      payee: {
        displayName: p.payee.display_name,
        handle: p.payee.handle,
        userId: p.payee.user_id
      },
      payer: {
        displayName: p.payer.display_name,
        handle: p.payer.handle,
        userId: p.payer.user_id
      },
      status: p.status
    }));
  }

  static async deletePayments(eventId: string) {
    await this.request(`events/${eventId}/settle`, {}, "DELETE");
  }

  //////////////////////////////// EXPENSE SPLITS ///////////////////////////////

  static async getExpenseSplits(eventId: string, expenseId: string) {
    const response = await this.request(`events/${eventId}/expenses/${expenseId}/splits`);

    return {
      expenseId: response.expense_id,
      contributions: response.splits.map(s => ({
        userId: s.contributor.user_id,
        displayName: s.contributor.display_name,
        handle: s.contributor.handle,
        amount: s.amount
      })),
      items: response.items ?
        response.items.map(i => ({
          itemId: i.id,
          name: i.name,
          amount: i.amount,
          splitBetween: i.split_between.map(u => ({
            userId: u.user_id,
            handle: u.handle,
            displayName: u.display_name
          }))
        })) : null
    };
  }

  static async addSplits(expense: {splits: ContributionInterface[], items: ItemInterface[]},
    eventId: string, expenseId: string) {


    let data;

    if (expense.items) {
      data = {
        splits: expense.splits.map(s => ({
          user_id: s.userId,
          amount: s.amount
        })),
        items: expense.items.map(i => ({
          name: i.name,
          amount: i.amount,
          user_ids: i.userIds
        }))
      };
    } else {
      data = {
        splits: expense.splits.map(s => ({
          user_id: s.userId,
          amount: s.amount
        }))
      };
    }

    await this.request(`events/${eventId}/expenses/${expenseId}/splits`, data, "POST");

  }

  static async updateSplits(expense: {splits: ContributionInterface[], items: ItemInterface[]},
     eventId: string, expenseId: string) {

    let data;

    if (expense.items) {
      data = {
        splits: expense.splits.map(s => ({
          user_id: s.userId,
          amount: s.amount
        })),
        items: expense.items.map(i => ({
          id: i.itemId,
          name: i.name,
          amount: i.amount,
          user_ids: i.userIds
        }))
      };
    } else {
      data = {
        splits: expense.splits.map(s => ({
          user_id: s.userId,
          amount: s.amount
        }))
      };
    }

    await this.request(`events/${eventId}/expenses/${expenseId}/splits`, data, "PUT");
  }

  static async deleteSplits(eventId: string, expenseId: string, userIds: number[]) {
    await this.request(`events/${eventId}/expenses/${expenseId}/splits`, { user_ids: userIds }, "DELETE");
  }

  static async deleteItems(eventId: string, expenseId: string, itemIds: number[]) {
    await this.request(`events/${eventId}/expenses/${expenseId}/items`, { item_ids: itemIds }, "DELETE");
  }


  //////////////////////////////// PAYMENTS ///////////////////////////////

  static async getAllPayments() {
    const response = await this.request(`payments`, {}, "GET");
    return response.payments.map(p => ({
      paymentId: p.id,
      amount: p.amount,
      event: {
        eventId: p.event.id,
        name: p.event.name
      },
      payee: {
        userId: p.payee.user_id,
        handle: p.payee.handle,
        displayName: p.payee.display_name
      },
      payer: {
        userId: p.payer.user_id,
        handle: p.payer.handle,
        displayName: p.payer.display_name
      },
      status: p.status
    }));
  }

  static async getPayment(paymentId: string) {
    const response = await this.request(`payments/${paymentId}`, {}, "GET");

    return {
      paymentId: response.id,
      payerId: response.payer.user_id,
      payeeId: response.payee.user_id,
      paymentDate: response.payment_date,
      reconciledDate: response.reconciled_date
    };
  }

  static async updatePayment(paymentId: string, paymentDate: string, reconciledDate: string) {
    const data = {
      payment_date: paymentDate,
      reconciled_date: reconciledDate
    };
    await this.request(`payments/${paymentId}`, data, "PUT");
  }


  //////////////////////////////// UNSUBSCRIBE ///////////////////////////////


  static async unsubscribe(email: string) {
    await this.request(`unsubscribe`, { email }, "POST");
  }
}

export default TabbilyApi;
