import { Routes, Route } from "react-router-dom";
import GroupDetailPage from "./group/GroupDetailPage";
import EventDetailPage from "./event/EventDetailPage";
import GroupsPage from "./group/GroupsPage";
import EventsPage from "./event/EventsPage";

import Homepage from "./home/Homepage";
import LoginPage from "./auth/LoginPage";
import ProfilePage from "./profile/ProfilePage";
import SignupPage from "./auth/SignupPage";
import { AppFunctions } from "./typing/interfaces";
import NotFound from "./common/NotFound";
import ForgotPassword from "./password/ForgotPassword";
import UnsubscribeForm from "./misc/UnsubscribeForm";

/** RoutesList Component
 *
 * Props: signup(), login()
 * State: none
 */


function RoutesList({ login, signup, updateUser }: AppFunctions) {
  return (
    <div className="RoutesList">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<LoginPage login={login} />} />
        <Route path="/signup" element={<SignupPage signup={signup} />} />
        <Route path="/profile" element={<ProfilePage updateUser={updateUser} />} />
        <Route path="/groups" element={<GroupsPage />} />
        <Route path="/groups/:groupId" element={<GroupDetailPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/events/:eventId" element={<EventDetailPage />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/unsubscribe" element={<UnsubscribeForm />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default RoutesList;