
import { useContext } from "react";
import { Navigate } from "react-router-dom";

import userContext from "../UserContext";

import { Button } from "reactstrap";
import EventList from "./EventList";
import { useModals } from "../utils/useModal";
import { useFetch } from "../utils/useFetch";
import CustomModal from "../components/CustomModal";
import LoadingSpinner from "../common/LoadingSpinner";
import ServerError from "../common/ServerError";

function EventsPage() {
  const { currentUser } = useContext(userContext);

  const [eventFormIsOpen, , toggleEventForm] = useModals('event');
  const [eventsIsLoading, events, eventsError] = useFetch("getAllEvents");

  if (!currentUser) return <Navigate to="/" />;

  if (eventsError) return <ServerError />;

  if (currentUser && eventsIsLoading ) return <LoadingSpinner />;

  return (
    <>
      {!eventsIsLoading &&
        <>
          <h3>My Events</h3>
          <Button onClick={toggleEventForm} className="btn my-2" color="primary" outline>+ New Event</Button>
          <EventList events={events} />
          <CustomModal type="event" title="Create New Event" isOpen={eventFormIsOpen} toggle={toggleEventForm} />
        </>
      }
    </>
  );
}

export default EventsPage;