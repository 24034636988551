import { useState, useEffect, useContext } from "react";

import UserContext from "../UserContext";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import { Option, Theme } from "../misc/Option";
import TabbilyApi from "../api";

import Alert from "../common/Alert";
import { useNavigate } from "react-router-dom";
import { findDifference } from "../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faPlus } from "@fortawesome/free-solid-svg-icons";

function AddEditGroupForm({ currentName = "", currentMembers = null, groupId = null, closeModal = null, refetchData = null }) {

  const { currentUser } = useContext(UserContext);
  const [friendsList, setFriendsList] = useState([]);
  const [name, setName] = useState(currentName);
  const [members, setMembers] = useState(currentMembers);
  const [formErrors, setFormErrors] = useState([]);

  const navigate = useNavigate();

  useEffect(
    function getOptionsList() {
      async function getFriends() {
        const allUsers = await TabbilyApi.getAllUsers();
        const allOtherUsers = allUsers.filter(u => u.userId !== currentUser.userId);
        setFriendsList(allOtherUsers.map((u) => (
          {
            value: u.userId,
            label: u.displayName,
            subLabel: `${u.handle ? "@" : ""}${u.handle || "(Invited)"}`
          }
        )));
      }

      getFriends();
    }, [currentUser]);

  async function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    try {
      if (!currentMembers) {
        const userIds = members.map((m) => m.value);
        const group = await TabbilyApi.createGroup(name, userIds);
        navigate(`/groups/${group.id}`);
      } else {
        const removed = findDifference(currentMembers, members, "value");
        removed.forEach(async (m) => await TabbilyApi.removeGroupMembers(m.value, groupId));
        const userIds = members.map((m) => m.value);
        await TabbilyApi.addGroupMembers(userIds, groupId);
        await TabbilyApi.updateGroup(name, groupId);
      }
      closeModal();
      refetchData();
    } catch (err) {
      setFormErrors(["Something went wrong with this request."]);
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="groupName">
            Name
          </Label>
          <Input
            id="groupName"
            name="groupName"
            placeholder="Name"
            type="text"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="selectMembers">
            {currentMembers ? "Edit Other Members" : "Add Other Members"}
          </Label>
          <Select
            id="selectMembers"
            options={friendsList}
            value={members}
            placeholder="Add members"
            onChange={setMembers}
            components={{ Option }}
            isClearable={false}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            isMulti
            theme={Theme}
          />
        </FormGroup>
        {formErrors.length ? (
          <Alert type="danger" messages={formErrors} />
        ) : null}
        <div className="d-flex flex-row justify-content-end">
          <Button type="submit" color="primary" className="text-dark">
            <FontAwesomeIcon icon={currentMembers ? faFloppyDisk : faPlus} /> {currentMembers ? "Save Group" : "Create Group"}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddEditGroupForm;