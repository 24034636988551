interface DifferenceInterface {
  value?: number,
  userId?: number,
  itemId?: number
}

function findDifference(arr1: DifferenceInterface[], arr2: DifferenceInterface[], key: string) {
  let result = arr1.filter(e => {
    return !arr2.some(item => item[key] === e[key]);
  });

  return result;
}

function formatCurrency(amount, currency = 'USD') {
  const formattedAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount / 100);
  return formattedAmount.slice(1);
}

export { findDifference, formatCurrency };