import React, { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, Card, CardBody, NavLink } from "reactstrap";

import { LoginInterface, AppFunctions } from "../typing/interfaces";

import Alert from "../common/Alert";
import userContext from "../UserContext";
import ShowHideInput from "../components/ShowHideInput";

/** LoginPage Component
 *
 * Props:
 * -login() to call in App
 *
 * State:
 * -formData, formErrors
 */

function LoginPage({ login }: Pick<AppFunctions, "login">) {
  const [formData, setFormData] = useState<LoginInterface>({
    usernameOrEmail: "",
    password: "",
  });
  const [alerts, setAlerts] = useState({
    type: "",
    messages: []
  });
  const { currentUser } = useContext(userContext);
  const navigate = useNavigate();

  /** Handle form submit:
   *
   * Calls login func prop and, if not successful, sets errors.
   */

  async function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    try {
      await login(formData);
      navigate("/");
    } catch (err) {
      setAlerts({type: "danger", messages: ["Invalid credentials."]});
    }
  }

  /** Update form data field */

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = evt.target;
    setFormData((l) => ({ ...l, [name]: value }));
  }

  return (
    <div className="LoginForm">
      {currentUser && <Navigate to="/" />}
      <div className="container col-md-6 offset-md-3">
        <h3 className="my-3">Log In</h3>
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <Label>Username or Email</Label>
                <Input
                  name="usernameOrEmail"
                  className="form-control"
                  value={formData.usernameOrEmail}
                  onChange={handleChange}
                  autoComplete="usernameOrEmail"
                  required
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label>Password</Label>
                <ShowHideInput
                  inputName="password"
                  inputValue={formData.password}
                  handleChange={handleChange}
                  required={true} />
              </FormGroup>
              {alerts.messages.length ? (
                <Alert type={alerts.type} messages={alerts.messages} />
              ) : null}
              <div className="d-grid">
                <Button className="text-dark" color="primary">
                  Log In
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <div className="d-flex justify-content-center">
          <NavLink href="/signup" className="mt-2">
            Don't have an account? Sign Up
          </NavLink>
        </div>
        <div className="d-flex justify-content-center">
          <NavLink href="/forgotPassword">
            <small>I forgot my password :(</small>
          </NavLink>

        </div>
      </div>
    </div>
  );
}

export default LoginPage;