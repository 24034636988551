import { faCheck, faPlus, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Row, Col } from "reactstrap";
import { formatCurrency } from "../utils/helpers";

/** LinkCard Component
 *
 * Props: listing {id, userId, photo, price, details}
 * State: None
 */

function ExpenseCard({ expense, showExpense }) {
  return (

    <Card className="col-12 m-1 text-dark" color="light">
        <Button onClick={showExpense} color="primary" className="text-dark">
          <CardBody className="p-0">
            <div className="d-flex flex-row justify-content-between my-0">
              <h6>{expense.name}</h6>
              {expense.fullyAllocated && <FontAwesomeIcon icon={faCheck} title="Fully Allocated"/>}
            </div>
            <Row className="my-0">
              <Col className="d-flex text-start  align-items-end">
                <CardSubtitle>Paid by {expense.paidBy.displayName} <small>{`${expense.paidBy.handle ? "@" : ""}${expense.paidBy.handle || "(Invited)"}`}</small>
                </CardSubtitle>
              </Col>
              <Col className="d-flex justify-content-end align-items-end col-2">
                <h6 className="m-0">${formatCurrency(expense.amount)}</h6>
              </Col>
            </Row>
          </CardBody>
        </Button>
    </Card>
  );
}

export default ExpenseCard;
