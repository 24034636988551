import React, { useState, useEffect, useCallback, useRef } from "react";

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import Select from "react-select";
import { Option, Theme } from "../misc/Option";

import Alert from "../common/Alert";
import ItemizedExpense from "./ItemizedExpense";

import { SelectOptionInterface } from "../typing/interfaces";
import SplitContributionForm from "./SplitContributionForm";
import TabbilyApi from "../api";
import { findDifference, formatCurrency } from "../utils/helpers";
import "./expense.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faInfoCircle, faLock, faLockOpen, faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useModals } from "../utils/useModal";
import ItemForm from "./ItemForm";
import SplitCard from "./SplitCard";


interface ExpenseInterface {
  expenseId?: string,
  name: string,
  amount: string,
  paidBy: SelectOptionInterface,
  contributions?: ContributionInterface[],
  items?: ItemInterface[],
}

export interface ContributionInterface {
  userId: number,
  amount: number,
  dollar: string,
  percent: string,
}

export interface ItemInterface {
  itemId?: number,
  name: string,
  amount: number,
  dollar: string,
  splitBetween: any[],
  userIds?: number[];
}

function AddExpenseForm({
  members,
  currentUser,
  eventId,
  eventLocked = false,
  currentExpense = null,
  editing = true,
  setIsEditing = null,
  setDeleteExpenseConfirm = null,
  closeModal = null,
  refetchData = null }) {

  const [alerts, setAlerts] = useState({
    type: "",
    messages: []
  });

  const initialFormRef = useRef<ExpenseInterface>(currentExpense && currentExpense.expenseId ? {
    expenseId: currentExpense.expenseId,
    name: currentExpense.name,
    amount: formatCurrency(currentExpense.amount),
    paidBy: {
      value: Number(currentExpense.paidBy.userId),
      label: currentExpense.paidBy.displayName,
      subLabel: `${currentExpense.paidBy.handle ? "@" : ""}${currentExpense.paidBy.handle || "(Invited)"}`
    },
    contributions: currentExpense.splitType === "ITEMIZED" ?
      currentExpense.contributions.map(c => ({
        userId: c.userId,
        displayName: c.displayName,
        handle: c.handle,
        amount: c.amount,
        dollar: formatCurrency(c.amount),
        percent: (c.amount / currentExpense.amount * 100).toFixed(2)
      })) :
      currentExpense.contributions.map(c => ({
        userId: c.userId,
        amount: c.amount,
        dollar: formatCurrency(c.amount),
        percent: (c.amount / currentExpense.amount * 100).toFixed(2)
      })),
    items: currentExpense.items ?
      currentExpense.items.map(i => ({
        name: i.name,
        amount: i.amount,
        itemId: i.itemId,
        splitBetween: i.splitBetween.map(u => ({
          value: u.userId,
          label: u.displayName,
          subLabel: `${u.handle ? "@" : ""}${u.handle || "(Invited)"}`
        }))
      })) :
      []
  } : {
    name: "",
    amount: "",
    paidBy: { value: Number(currentUser.userId), label: currentUser.displayName },
    contributions: members.map(m => ({
      userId: m.value,
      amount: 0,
      dollar: "",
      percent: ""
    })),
    items: []
  });

  const unallocatedRef = useRef(currentExpense ? {
    amount: calculateUnallocated(currentExpense.amount, currentExpense.contributions),
    dollar: formatCurrency(calculateUnallocated(currentExpense.amount, currentExpense.contributions)),
    percent: (calculateUnallocated(currentExpense.amount, currentExpense.contributions) / currentExpense.amount * 100).toFixed(2)
  } : {
    amount: 0,
    dollar: "0.00",
    percent: "0"
  });

  const participantRef = useRef(currentExpense && currentExpense.expenseId ?
    currentExpense.contributions.map(c => ({
      value: c.userId,
      label: c.displayName,
      subLabel: `${c.handle ? "@" : ""}${c.handle || "(Invited)"}`,
      icon: "cat-solid.svg"
    })) : members);


  const splitRef = useRef(
    currentExpense &&
      !(!currentExpense.contributions.length || (currentExpense.contributions[0].amount === Math.floor(currentExpense.amount / participantRef.current.length)
        && currentExpense.contributions.every(c => (Math.abs(c.amount - currentExpense.contributions[0].amount) <= 1)))) ?
      false : true);

  const initialTotalRef = useRef(currentExpense && currentExpense.expenseId ? Number(currentExpense.amount) : 0);

  const [formData, setFormData] = useState<ExpenseInterface>(initialFormRef.current);
  const [unallocated, setUnallocated] = useState(unallocatedRef.current);
  const [total, setTotal] = useState(initialTotalRef.current);

  const [itemized, setItemized] = useState(currentExpense && currentExpense.expenseId && currentExpense.splitType === "ITEMIZED");

  const [participants, setParticipants] = useState<SelectOptionInterface[]>(participantRef.current);
  const [splitEvenly, setSplitEvenly] = useState(splitRef.current);
  const [disableSave, setDisableSave] = useState(false);

  const [currentItem, setCurrentItem] = useState(null);
  const [itemModal, closeItemModal, toggleItemModal] = useModals("item");
  const [activeTab, setActiveTab] = useState("1");

  const [lockedRows, setLockedRows] = useState(new Set());

  function calculateUnallocated(total: number, contributions: { amount: number; }[]) {
    const currentSum = contributions.reduce((prev, curr) => prev + curr.amount, 0);
    return total - currentSum;
  }

  const calculateEvenSplit = useCallback(() => {
    const numParticipants = participants.length;
    const amount = total / numParticipants;
    const amounts = Array.from({ length: numParticipants }).fill(Math.ceil(amount)) as number[];

    if (amount !== Math.floor(amount)) {
      const sum = amounts.reduce((prev, curr) => prev + Math.ceil(curr), 0);
      const difference = sum - total;

      for (let i = 0; i < difference; i++) {
        amounts[i] = Math.floor(amount);
      }
    }

    const dollar = amounts.map(a => formatCurrency(a));
    const percent = (100 / numParticipants).toFixed(2);

    const contributions = participants.map((m, idx) => ({
      userId: m.value,
      amount: amounts[idx],
      dollar: dollar[idx],
      percent
    }));

    const unallocated = {
      amount: 0,
      dollar: "0.00",
      percent: "0"
    };
    setFormData((l) => ({ ...l, contributions }));
    setUnallocated(unallocated);
  }, [participants, total]);


  const updateUnallocatedAmount = useCallback(() => {
    const amount = calculateUnallocated(total, formData.contributions);
    const percent = amount / total * 100;

    const unallocated = {
      amount,
      dollar: formatCurrency(amount),
      percent: isNaN(percent) ? "0" : percent.toFixed(2)
    };

    setUnallocated(unallocated);
  }, [total, formData.contributions]);

  useEffect(
    function resetContributions() {
      if (splitEvenly && participants.length > 0) {
        calculateEvenSplit();
      }
    }, [splitEvenly, calculateEvenSplit, participants]);

  useEffect(
    function updateUnallocatedDisplay() {
      if (!splitEvenly && !itemized) {
        updateUnallocatedAmount();
      }
    }, [updateUnallocatedAmount, participants, splitEvenly, itemized]);

  useEffect(
    function revertExpense() {
      function resetExpenses() {
        setParticipants(participantRef.current);
        setFormData(initialFormRef.current);
      }
      if (!editing) resetExpenses();
    }, [editing, initialFormRef, participantRef]);

  function updateDollarAmount() {
    const contributions = [...formData.contributions];
    contributions.forEach(c => {
      c.amount = total * Number(c.percent) / 100;
      c.dollar = formatCurrency(c.amount);
    });

    setFormData((l) => ({ ...l, contributions }));
  }

  function handleBlur(evt: React.ChangeEvent<HTMLInputElement>) {
    const amount = evt.target.value;
    setFormData((l) => ({ ...l, amount: formatCurrency(Number(amount.replace(/,/g, '')) * 100) }));
    setTotal(Math.round(Number(amount.replace(/,/g, '')) * 100));
    if (!splitEvenly) updateDollarAmount();
  }


  /** Update form data field */

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>, idx: number = null) {
    const { name, value } = evt.currentTarget;

    if (idx === null) {
      if (name === "amount") {
        setFormData((l) => ({ ...l, [name]: value.replace(/[^0-9,.]/, '') }));
      } else {
        setFormData((l) => ({ ...l, [name]: value }));
      }
    } else {
      const contributions = [...formData.contributions];

      if (name === "dollar") {
        contributions[idx][name] = value.replace(/[^0-9,.]/, '');
      } else {
        contributions[idx][name] = value;
      }
      setFormData((l) => ({ ...l, contributions }));
      setSplitEvenly(false);
    }
  }

  function handleContributionBlur(idx: number, evt: React.ChangeEvent<HTMLInputElement>, decimal: number) {
    const { name, value } = evt.target;
    const contributions = formData.contributions;

    if (name === "dollar") {
      contributions[idx].amount = Math.round(Number(value.replace(/,/g, '')) * 100);
      const percent = contributions[idx].amount / total * 100;
      contributions[idx].percent = isNaN(percent) ? "0" : percent.toFixed(2);
      contributions[idx].dollar = formatCurrency(contributions[idx].amount);
    } else {
      contributions[idx].amount = Math.round(Number(value) / 100 * total);
      contributions[idx].dollar = formatCurrency(contributions[idx].amount);
      contributions[idx].percent = Number(value).toFixed(2);
    }

    setFormData((l) => ({ ...l, contributions }));
    updateUnallocatedAmount();
  }

  function handleRemoveParticipant(idx: number) {
    const newParticipants = [...participants];
    newParticipants.splice(idx, 1);
    setParticipants(newParticipants);

    const newContributions = [...formData.contributions];
    newContributions.splice(idx, 1);
    setFormData((l) => ({ ...l, contributions: newContributions }));
  }

  function handleChangeSelect(selected: SelectOptionInterface[]) {
    const prevParticipants = participants;
    let newContribution;
    let newContributions;

    if (prevParticipants.length < selected.length) {
      const idx = selected.length - 1;

      newContribution = {
        userId: selected[idx].value,
        amount: 0,
        dollar: "",
        percent: ""
      };

      newContributions = [...formData.contributions, newContribution];
    } else {
      const idx = findDifference(prevParticipants, selected, "value")[0].value;
      newContributions = formData.contributions.filter(p => Number(p.userId) !== idx);
    }

    setParticipants(selected);
    setFormData((l) => ({ ...l, contributions: newContributions }));
  }

  function handleRemoveItem(idx: number) {
    const newItems = [...formData.items];
    newItems.splice(idx, 1);
    setFormData(l => ({ ...l, items: newItems }));
  }

  function toggleLock(userId: number) {
    if (lockedRows.has(userId)) lockedRows.delete(userId)
    else lockedRows.add(userId)
  }

  async function handleSubmit(evt) {
    evt.preventDefault();
    setAlerts({ type: "info", messages: [] });

    if ((itemized && verifyTotal("items")) || (!itemized && verifyTotal("contributions"))) {
      setDisableSave(true);
      setAlerts({ type: "info", messages: [] });

      if (itemized) {
        formData.items = formData.items.map(i => ({ ...i, userIds: i.splitBetween.map(u => u.value) }));
      } else {
        delete formData.items;
      }

      const expenseData = { ...formData, amount: total };

      try {
        let response;
        if (formData.expenseId === undefined) {
          response = await TabbilyApi.createExpense(expenseData, eventId);
          await TabbilyApi.addSplits({ splits: expenseData.contributions, items: expenseData.items }, eventId, response.id);
        } else {
          let splitResponse;
          if (itemized) {

            const deletedItems = findDifference(initialFormRef.current.items, formData.items, "itemId");
            const deletedIds = deletedItems.map(i => i.itemId);
            if (deletedIds.length) await TabbilyApi.deleteItems(eventId, currentExpense.expenseId, deletedIds);

            response = await TabbilyApi.updateExpense(expenseData, eventId, formData.expenseId);
            await TabbilyApi.updateSplits({ splits: expenseData.contributions, items: expenseData.items }, eventId, currentExpense.expenseId);
            splitResponse = await TabbilyApi.getExpenseSplits(eventId, currentExpense.expenseId);

          } else {
            const deletedContributions = findDifference(initialFormRef.current.contributions, formData.contributions, "userId");
            const deletedIds = deletedContributions.map(c => c.userId);
            if (deletedIds.length) await TabbilyApi.deleteSplits(eventId, currentExpense.expenseId, deletedIds);

            response = await TabbilyApi.updateExpense(expenseData, eventId, formData.expenseId);
            await TabbilyApi.updateSplits({ splits: expenseData.contributions, items: expenseData.items }, eventId, currentExpense.expenseId);
            splitResponse = await TabbilyApi.getExpenseSplits(eventId, currentExpense.expenseId);
          }

          splitResponse.contributions = splitResponse.contributions.map(c => ({
            userId: c.userId,
            displayName: c.displayName,
            handle: c.handle,
            amount: c.amount,
            dollar: formatCurrency(c.amount),
            percent: (c.amount / response.amount * 100).toFixed(2)
          }));

          splitResponse.items = itemized ? splitResponse.items.map(i => ({
            name: i.name,
            amount: i.amount,
            itemId: i.itemId,
            splitBetween: i.splitBetween.map(u => ({
              value: u.userId,
              label: u.displayName,
              subLabel: `${u.handle ? "@" : ""}${u.handle || "(Invited)"}`
            }))
          })) : [];

          for (let key in initialFormRef.current) {
            if (key === "amount") {
              initialFormRef.current[key] = formatCurrency(response[key]);
            } else if (key === "paidBy") {
              initialFormRef.current[key] = {
                value: Number(response[key].userId),
                label: response[key].displayName,
                subLabel: `${response[key].handle ? "@" : ""}${response[key].handle || "(Invited)"}`
              };
            } else {
              initialFormRef.current[key] = response[key] || splitResponse[key];
            }
          }
          participantRef.current = participants;
          formData.contributions = splitResponse.contributions;
          if (itemized) formData.items = splitResponse.items;
        }
        setAlerts({ type: "success", messages: ["Expense saved!"] });
        if (closeModal) closeModal();
        if (refetchData) refetchData();
      } catch (err) {
        setAlerts({ type: "danger", messages: ["Something went wrong with your request."] });
      }

      setDisableSave(false);
    } else {
      setAlerts({ type: "danger", messages: ["Sum of contributions or items exceeds amount specified."] });
    }
  }

  function verifyTotal(type: string) {
    const splits = [...formData[type]];
    let currentTotal = 0;

    for (let c of splits) {
      currentTotal += c.amount;
    }
    return currentTotal <= total;
  }

  const itemizedView = currentExpense ?
    <>
      <Nav tabs className="my-4 info">
        <NavItem className="col-6 text-center">
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
          >
            Itemized View
          </NavLink>
        </NavItem>
        <NavItem className="col-6 text-center">
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
            Split View
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ItemizedExpense
            setCurrentItem={setCurrentItem}
            handleRemoveItem={handleRemoveItem}
            items={formData.items || []}
            toggle={toggleItemModal}
            editing={editing}
          />
        </TabPane>
        <TabPane tabId="2">
          <h6>Contributors</h6>
          <div>
            {formData.contributions.map(c => <SplitCard key={`${c.amount}${c.userId}`} split={c} />)}
          </div>
        </TabPane>
      </TabContent>
    </> :
    <ItemizedExpense
      setCurrentItem={setCurrentItem}
      handleRemoveItem={handleRemoveItem}
      items={formData.items || []}
      toggle={toggleItemModal}
      editing={true}
    />;

  return (
    <>
      <Form onSubmit={handleSubmit} className="d-flex flex-column">
        <fieldset disabled={!editing}>
          <FormGroup>
            <Label for="name">
              Name
            </Label>
            <Input
              id="name"
              name="name"
              placeholder="Name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <Label for="amount">
            Total Amount <span id="ItemizedInfo"><FontAwesomeIcon icon={faInfoCircle} className="info-icon" /></span>
          </Label>
          <UncontrolledTooltip
            placement="right"
            target="ItemizedInfo"
          >
            Total bill (including tax and tip)
          </UncontrolledTooltip>
          <FormGroup>
            <div className="input-icon">
              <i>$</i>
              <Input
                id="amount"
                name="amount"
                placeholder="0.00"
                type="text"
                // step="0.01"
                inputMode="decimal"
                // pattern="[0-9 \,]"
                value={formData.amount}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="paidBy">
              Paid By
            </Label>
            <Select
              id="paidBy"
              name="paidBy"
              options={members}
              value={formData.paidBy}
              onChange={selected => (setFormData(l => ({ ...l, paidBy: selected })))}
              isDisabled={!editing}
              components={{ Option }}
              theme={Theme}
              required
            />
          </FormGroup>
          <FormGroup switch className="lg" >
            <span id="Itemize">
              <Label check>Itemized</Label>
            </span>
            <Input
              type="switch"
              checked={itemized}
              onChange={() => {
                setItemized(!itemized);
              }}
            />

            <UncontrolledTooltip
              placement="right"
              target="Itemize"
            >
              Switching modes will delete all current expense information on save.
            </UncontrolledTooltip>
          </FormGroup>
          <hr></hr>
          {itemized && itemizedView}
          {!itemized &&
            <>
              <div className="d-flex flex-row justify-content-between">
                <Label for="selectMembers">
                  Participants
                </Label>
                <FormGroup switch className="lg" >
                  <Label check>Split Evenly</Label>
                  <Input
                    type="switch"
                    checked={splitEvenly}
                    onChange={() => {
                      setSplitEvenly(!splitEvenly);
                      setAlerts({ type: "info", messages: [] });
                    }}
                  />
                </FormGroup>
              </div>
              <FormGroup>
                <Select
                  id="selectMembers"
                  options={members}
                  defaultValue={participants}
                  value={participants}
                  placeholder="Add participants"
                  onChange={handleChangeSelect}
                  isMulti
                  theme={Theme}
                  components={{ Option }}
                  isDisabled={!editing}
                  isClearable={false}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  className="select-input"
                />
              </FormGroup>
            </>
          }
          {!itemized && participants.length > 0 &&
            <>
              <Row className="d-flex align-items-center justify-content-between my-0 p-0">
                <Col xs={4} className="flex-grow-1">
                  <h6>Participant</h6>
                </Col>
                <Col xs={4} md={3} className="p-sm-0 p-md-1">
                  <h6>Amount</h6>
                </Col>
                <Col xs={3} md={2} className="p-0 mx-md-3">
                  <h6>Percent</h6>
                </Col>
                <Col xs={1} md={1} className="p-0">
                </Col>
              </Row>
              <SplitContributionForm
                participants={participants}
                formData={formData}
                handleContributionBlur={handleContributionBlur}
                handleChange={handleChange}
                handleRemoveParticipant={handleRemoveParticipant}
                toggleLock={toggleLock}
              />
            </>}
          <hr></hr>
          {!itemized &&
            <>
              <Row className="justify-content-between">
                <Col xs={4}>
                  <small>Unallocated Amount</small>
                </Col>
                <Col xs={3} md={3}>
                  <div className="input-icon">
                    <span>
                      ${unallocated.dollar}
                    </span>
                  </div>
                </Col>
                <Col xs={3} md={3}>
                  <div className="input-icon input-icon-right">
                    <span>
                      {unallocated.percent}
                    </span>
                    <i>%</i>
                  </div>
                </Col>
                <Col xs={2} md={2}>
                </Col>
              </Row>
              <hr></hr>
            </>
          }
        </fieldset>
        <div className={currentExpense ? "d-flex flex-row justify-content-between" : "d-flex flex-row justify-content-end"}>
          {currentExpense && <fieldset disabled={eventLocked}>
            <div id="EditDelButtons" className="d-flex flex-row justify-content-start">
              <Button className="btn btn-secondary" onClick={() => setDeleteExpenseConfirm(true)} title="Delete Expense">
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
              <Button className="mx-2" color="primary" onClick={() => setIsEditing(!editing)} disabled={false} title={editing ? "Cancel Edit" : "Edit"}>
                <FontAwesomeIcon icon={editing ? faLockOpen : faLock} />
              </Button>
            </div>
            {eventLocked && <UncontrolledTooltip
              placement="right"
              target="EditDelButtons"
            >
              Expenses are not editable once event has been settled.
            </UncontrolledTooltip>}
          </fieldset>

          }
          <Button type="submit" className="text-dark btn-info" disabled={!editing || disableSave}>
            <FontAwesomeIcon icon={faFloppyDisk} /> Save
          </Button>
        </div>

        {alerts.messages.length ? (
          <Alert type={alerts.type} messages={alerts.messages} />
        ) : null}

      </Form>
      <Modal isOpen={itemModal} >
        <ModalHeader toggle={toggleItemModal} className="bg-light">
          {currentItem !== null ? "Edit Item" : "New Item"}
        </ModalHeader>
        <ModalBody>
          <ItemForm
            idx={currentItem !== null ? currentItem : null}
            setParentFormData={setFormData}
            prevItems={formData.items}
            members={members}
            closeModal={closeItemModal}
            handleRemoveItem={handleRemoveItem}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddExpenseForm;
