
import { useContext } from "react";
import { Navigate } from "react-router-dom";

import userContext from "../UserContext";
import { Button } from "reactstrap";

import GroupList from "./GroupList";
import { useModals } from "../utils/useModal";
import { useFetch } from "../utils/useFetch";
import CustomModal from "../components/CustomModal";
import LoadingSpinner from "../common/LoadingSpinner";
import ServerError from "../common/ServerError";

function GroupsPage() {
  const { currentUser } = useContext(userContext);
  const [groupFormIsOpen, , toggleGroupForm] = useModals('group');
  const [groupsIsLoading, groups, groupsError] = useFetch("getAllGroups");


  if (!currentUser) return <Navigate to="/" />;

  if (groupsError) return <ServerError />;

  if (currentUser && groupsIsLoading ) return <LoadingSpinner />;

  return (
    <>
      {!groupsIsLoading && <>
        <h3>My Groups</h3>
        <Button onClick={toggleGroupForm} className="btn my-2" color="primary" outline>+ New Group</Button>
        <CustomModal type="group" title="Create New Group" isOpen={groupFormIsOpen} toggle={toggleGroupForm} />
        <GroupList groups={groups} />
      </>
      }
    </>
  );
}

export default GroupsPage;