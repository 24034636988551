import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input, Card, CardBody } from "reactstrap";

import TabbilyApi from "../api";
import CheckEmail from "../common/CheckEmail";

/** ProfilePage Component
 *
 * Props:
 * -login() to call in App
 *
 * State:
 * -formData, formErrors
 */

function ResetPassword() {
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [success, setSuccess] = useState(false);

  async function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    await TabbilyApi.resetPassword(usernameOrEmail);
    setSuccess(true);
  }

  return (
    <div className="container col-md-6 offset-md-3">
      {!success && <>
        <h3 className="my-3">Reset Password</h3>
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <Label>Username or Email</Label>
                <Input
                  name="usernameOrEmail"
                  className="form-control"
                  value={usernameOrEmail}
                  onChange={(evt) => setUsernameOrEmail(evt.target.value)}
                  autoComplete="usernameOrEmail"
                  required
                />
              </FormGroup>
              <div className="d-grid">
                <Button className="text-dark" color="primary">
                  Reset Password
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </>}
      {success && <CheckEmail />}
    </div>
  );
}

export default ResetPassword;