import { useEffect, useState, useContext, useCallback } from "react";
import UserContext from "../UserContext";

import { useParams, useNavigate, Navigate } from "react-router-dom";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  NavLink,
  Input,
  FormGroup,
  Label,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  UncontrolledTooltip
} from "reactstrap";

import TabbilyApi from "../api";
import AddExpenseForm from "../expense/AddExpenseForm";
import NotFound from "../common/NotFound";
import Confirmation from "../components/Confirmation";

import { formatCurrency } from "../utils/helpers";
import { useModals } from "../utils/useModal";
import { useFetch } from "../utils/useFetch";
import CustomModal from "../components/CustomModal";
import LoadingSpinner from "../common/LoadingSpinner";
import PaymentStatusForm from "../payment/PaymentStatusForm";
import ServerError from "../common/ServerError";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ExpenseCard from "../expense/ExpenseCard";
import PaymentCard from "../payment/PaymentCard";
import ItemizedExpenseForm from "../expense/ItemizedExpense";

function EventDetailPage() {
  const { currentUser } = useContext(UserContext);
  const { eventId } = useParams();
  const navigate = useNavigate();

  const [eventIsLoading, event, eventError, refetchEvent] = useFetch("getEvent", eventId);
  const [expensesIsLoading, expenses, expensesError, refetchExpenses] = useFetch(event ? "getAllExpenses" : null, eventId);
  const [groupIsLoading, group, groupError] = useFetch(
    event ? "getGroup" : null,
    event ? event.groupId : null);

  const [groupMembers, setGroupMembers] = useState(null);
  const [expenseModal, setExpenseModal] = useState(false);
  const [currentExpense, setCurrentExpense] = useState({ expenseId: null, isLoading: true });
  const [isEditing, setIsEditing] = useState(false);
  const [deleteEventConfirm, setDeleteEventConfirm] = useState(false);
  const [deleteExpenseConfirm, setDeleteExpenseConfirm] = useState(false);

  const [eventFormIsOpen, closeEventFormModal, toggleEventForm] = useModals('event');
  const [paymentFormIsOpen, setPaymentFormOpen] = useState(false);
  const [currentPayment, setCurrentPayment] = useState({ paymentId: null, payeeId: null, isLoading: true });

  const [forceSettleConfirm, setForceSettleConfirm] = useState(false);


  const settleConfirmToggle = () => setForceSettleConfirm(!forceSettleConfirm);
  const confirmToggle = () => setDeleteEventConfirm(!deleteEventConfirm);
  const expenseConfirmToggle = () => setDeleteExpenseConfirm(!deleteExpenseConfirm);

  const toggle = () => setExpenseModal(!expenseModal);

  const [expenseDetailModal, setExpenseDetailModal] = useState(false);

  const toggleDetail = () => {
    setExpenseDetailModal(!expenseDetailModal);
    setCurrentExpense({ expenseId: null, isLoading: true });
    setIsEditing(false);
  };

  const togglePaymentModal = () => {
    setPaymentFormOpen(!paymentFormIsOpen);
    setCurrentPayment({ paymentId: null, payeeId: null, isLoading: true });
  };

  useEffect(
    function loadEventInfo() {
      function getEvent() {
        if (group) {
          const members = group.members.map(m => ({
            value: m.id,
            label: m.displayName,
            subLabel: `${m.handle ? "@" : ""}${m.handle || "(Invited)"}`
          }));
          setGroupMembers(members);
        }
      }

      getEvent();
    }, [group]);

  const getExpenseInfo = useCallback(async () => {
    try {
      const response = await TabbilyApi.getExpense(eventId, currentExpense.expenseId);
      const splits = await TabbilyApi.getExpenseSplits(eventId, currentExpense.expenseId);

      const expense = {
        ...response,
        contributions: splits.contributions,
        items: splits.items
      };

      setCurrentExpense({ ...expense, isLoading: false });
    } catch (err) {

    }
  }, [eventId, currentExpense.expenseId]);

  useEffect(
    function loadExpenseInfo() {
      if (currentExpense.expenseId) getExpenseInfo();
    }, [getExpenseInfo, currentExpense.expenseId]);


  const getPaymentInfo = useCallback(async () => {
    try {
      const response = await TabbilyApi.getPayment(currentPayment.paymentId);
      setCurrentPayment({ ...response, isLoading: false });
    } catch (err) {

    }
  }, [currentPayment.paymentId]);

  useEffect(
    function loadPaymentInfo() {
      if (currentPayment.paymentId) getPaymentInfo();
    }, [getPaymentInfo, currentPayment.paymentId]);


  async function settleExpenses() {
    try {
      await TabbilyApi.settleExpenses(eventId);
    } catch (err) {
      setForceSettleConfirm(true);
    }

    refetchEvent();
  }

  async function forceSettleExpenses() {
    try {
      await TabbilyApi.settleExpenses(eventId, true);
      setForceSettleConfirm(false);
    } catch (err) {

    }
    refetchEvent();
  }

  async function deletePayments() {
    await TabbilyApi.deletePayments(eventId);
    refetchEvent();
  }

  async function deleteExpense() {
    await TabbilyApi.deleteExpense(eventId, currentExpense.expenseId);
    refetchExpenses();
    refetchEvent();
    setDeleteExpenseConfirm(false);
    setCurrentExpense({ expenseId: null, isLoading: true });
    setExpenseDetailModal(false);
  }

  async function deleteEvent() {
    await TabbilyApi.deleteEvent(eventId);
    navigate("/events");
  }

  if (!currentUser) return <Navigate to="/" />;

  if (eventError) return <NotFound />;

  if (expensesError || groupError) return <ServerError />;

  if (eventIsLoading || expensesIsLoading || groupIsLoading) return <LoadingSpinner />;

  return (
    <>
      {!eventIsLoading && !expensesIsLoading && !groupIsLoading &&
        <>
          <div className="d-flex flex-row align-items-baseline justify-content-center px-3">

            <h3 className="px-2">{event.name}</h3>
            <FontAwesomeIcon icon={faPenToSquare} onClick={toggleEventForm} className="icon-button" />
          </div>
          <div className="d-flex justify-content-center">
            <NavLink href={`/groups/${group.id}`}><h5>{group.name}</h5></NavLink>
          </div>
          <div><h6>Total: ${formatCurrency(event.total)}</h6></div>
          <UncontrolledAccordion defaultOpen="2" stayOpen className="my-3 col-md-8 mx-auto">
            <AccordionItem>
              <AccordionHeader targetId="1" >
                Expenses
                {/* <div>${formatCurrency(event.total)} Total</div> */}
              </AccordionHeader>
              <AccordionBody accordionId="1">
                {/* <Table hover className="text-dark">
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>
                        Item Name
                      </th>
                      <th>
                        Paid By
                      </th>
                      <th>
                        Amount
                      </th>
                      <th>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenses.map((e, idx: number) => (
                      <tr key={`${idx}${e.name}${e.amount}`}>
                        <th scope="row">
                          {idx + 1}
                        </th>
                        <td>
                          {e.name}
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            {e.paidBy.displayName}
                            <small>{`${e.paidBy.handle ? "@" : ""}${e.paidBy.handle || "(Invited)"}`}</small>
                          </div>
                        </td>
                        <td>
                          ${formatCurrency(e.amount)}
                        </td>
                        <td>
                          <Button color="primary" size="sm" className="edit-expense"
                            onClick={() => {
                              setCurrentExpense({ expenseId: e.id, isLoading: true });
                              setExpenseDetailModal(true);
                            }}>
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th scope="row">
                        Total
                      </th>
                      <td>
                      </td>
                      <td>
                      </td>
                      <td>
                        ${formatCurrency(event.total)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table> */}
                <div className="d-flex flex-column align-items-center">
                  <div id="NewExpense" className="w-100">
                    <Button onClick={toggle}  className="w-100 my-1 text-dark" color="info" disabled={!!event.expectedPayments} outline>
                      <FontAwesomeIcon icon={faPlus} /> New Expense
                    </Button>
                  </div>
                  {expenses.map(e => <ExpenseCard key={`expense-${e.id}`} expense={e} showExpense={() => {
                    setCurrentExpense({ expenseId: e.id, isLoading: true });
                    setExpenseDetailModal(true);
                  }} />)}
                </div>
                {event.expectedPayments && <UncontrolledTooltip
                  placement="top"
                  target="NewExpense"
                >
                  Expenses are not editable once event has been settled.
                </UncontrolledTooltip>}
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="2">
                Final Tally
              </AccordionHeader>
              <AccordionBody accordionId="2">
                {!event.expectedPayments && <>
                  <Button onClick={settleExpenses} className="text-dark btn-info w-100">Settle Event</Button>
                  <div className="text-dark"><small>Settling event will lock expenses.</small></div>
                </>}
                {event.expectedPayments &&
                  <>
                    <div className="d-flex flex-column align-items-center">
                      {event.expectedPayments.map(p =>
                        <PaymentCard key={`payment-${p.id}`} payment={p} modifyPayment={() => {
                          setCurrentPayment({ paymentId: p.id, payeeId: null, isLoading: true });
                          setPaymentFormOpen(true);
                        }}
                          disabled={!(currentUser.userId === p.payee.userId || currentUser.userId === p.payer.userId)}
                        />)}
                    </div>
                    {/* <Table hover className="text-dark">
                      <thead>
                        <tr>
                          <th>
                            Payer
                          </th>
                          <th>
                            Payee
                          </th>
                          <th>
                            Amount
                          </th>
                          <th>
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {event.expectedPayments.map((p, idx: number) => (
                          <tr key={`${p.payee.displayName}${p.payer.displayName}${p.amount}`}
                            onDoubleClick={() => {
                              if (currentUser.userId === p.payee.userId || currentUser.userId === p.payer.userId) {
                                setCurrentPayment({ paymentId: p.id, payeeId: null, isLoading: true });
                                setPaymentFormOpen(true);
                              }
                            }
                            }>
                            <td>
                              <div className="d-flex flex-column">
                                {p.payer.displayName}
                                <small>{`${p.payer.handle ? "@" : ""}${p.payer.handle || "(Invited)"}`}</small>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-column">
                                {p.payee.displayName}
                                <small>{`${p.payee.handle ? "@" : ""}${p.payee.handle || "(Invited)"}`}</small>
                              </div>
                            </td>
                            <td>
                              ${formatCurrency(p.amount)}
                            </td>
                            <td>
                              {p.status}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table> */}
                    <Button onClick={deletePayments} color="secondary" className="w-100 my-1">Clear Tally</Button>
                    <div className="text-dark"><small>Unlock expenses and delete current expected payments.</small></div>
                    <Modal isOpen={paymentFormIsOpen} toggle={togglePaymentModal}>
                      <ModalHeader toggle={togglePaymentModal} className="bg-light">
                        Modify Payment Status
                      </ModalHeader>
                      <ModalBody>
                        {!currentPayment.isLoading &&
                          <PaymentStatusForm
                            currentPayment={currentPayment}
                            isPayee={currentUser.userId === currentPayment?.payeeId}
                            closeModal={togglePaymentModal}
                            refetchData={refetchEvent}
                          />}
                      </ModalBody>
                    </Modal>
                  </>
                }
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
          <Confirmation
            isOpen={forceSettleConfirm}
            toggle={settleConfirmToggle}
            confirm={forceSettleExpenses}
            cancel={() => setForceSettleConfirm(false)}
            message="Some expenses are not fully reconciled. Do you want to settle anyway?" />
          <CustomModal
            type="event"
            title="Edit Event"
            isOpen={eventFormIsOpen}
            toggle={toggleEventForm}
            edit
            data={{ currentName: event.name, group: { value: group.id, label: group.name }, eventId }}
            closeModal={closeEventFormModal}
            refetchData={refetchEvent}
          />
          <Button className="mx-2" onClick={() => setDeleteEventConfirm(!deleteEventConfirm)} outline>
            <FontAwesomeIcon icon={faTrashCan} /> Delete Event
          </Button>
          <Confirmation
            isOpen={deleteEventConfirm}
            toggle={confirmToggle}
            confirm={deleteEvent}
            cancel={() => {
              setDeleteEventConfirm(false);
              setCurrentExpense({ ...currentExpense });
              getExpenseInfo();
            }}
            message="Are you sure you want to delete this event? All expenses will be lost." />

          <Modal isOpen={expenseModal} toggle={toggle} >
            <ModalHeader toggle={toggle} className="bg-light">
              Add New Expense
            </ModalHeader>
            <ModalBody>
              <AddExpenseForm
                members={groupMembers}
                currentUser={currentUser}
                eventId={eventId}
                closeModal={() => setExpenseModal(false)}
                refetchData={() => {
                  refetchExpenses();
                  refetchEvent();
                }}
              />
            </ModalBody>
          </Modal>
          <Modal isOpen={expenseDetailModal} toggle={toggleDetail} >
            <ModalHeader toggle={toggleDetail} className="bg-light">
              {isEditing ? "Edit Expense Details" : "Expense Details"}
            </ModalHeader>
            <ModalBody>
              {!currentExpense.isLoading &&
                <AddExpenseForm
                  members={groupMembers}
                  currentUser={currentUser}
                  eventId={eventId}
                  currentExpense={currentExpense}
                  editing={isEditing}
                  eventLocked={!!event.expectedPayments}
                  refetchData={() => {
                    refetchExpenses();
                    refetchEvent();
                  }}
                  setIsEditing={setIsEditing}
                  setDeleteExpenseConfirm={setDeleteExpenseConfirm}
                />}
            </ModalBody>
          </Modal>
          <Confirmation
            isOpen={deleteExpenseConfirm}
            toggle={expenseConfirmToggle}
            confirm={deleteExpense}
            cancel={() => setDeleteExpenseConfirm(false)}
            message="Are you sure you want to delete this expense?" />
        </>
      }

    </>
  );
}

export default EventDetailPage;