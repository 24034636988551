import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import { Button, Form, FormGroup, Label, Input, Card, CardBody, NavLink } from "reactstrap";

import { SignupInterface, AppFunctions } from "../typing/interfaces";
import Alert from "../common/Alert";
import userContext from "../UserContext";

import { useQuery } from "../utils/useQuery";
import TabbilyApi from "../api";

import ShowHideInput from "../components/ShowHideInput";

/** Signup form.
 *
 * Shows form and manages update to state on changes.
 * On submission:
 * - calls signup function prop
 *
 * Props: signup() to call in App
 * State: formData, formErrors
 *
 * Routes -> SignupPage -> Alert
 * Routed as /signup
 */

function SignupPage({ signup }: Pick<AppFunctions, "signup">) {
  const navigate = useNavigate();
  const token = useQuery();
  const [formData, setFormData] = useState<SignupInterface>({
    username: "",
    displayName: "",
    email: "",
    password: "",
    invitationCode: ""
  });
  const [formErrors, setFormErrors] = useState([]);
  const { currentUser } = useContext(userContext);


  useEffect(
    function loadInvitedUserOnMount() {
      async function getInvitedUser() {
        try {
          const response = await TabbilyApi.getInvitedUser(token.get("inv"));
          setFormData(l => ({ ...l, ...response, invitationCode: token.get("inv") }));
        } catch (err) {
          setFormErrors(["Sorry, the invitation code is either invalid or has expired."]);
        }
      }

      if (token.get("inv")) getInvitedUser();
    }, [token]);

  /** Handle form submit:
   *
   * Calls login func prop and, if not successful, sets errors.
   */

  async function handleSubmit(evt) {
    evt.preventDefault();

    if (/^[a-zA-Z0-9](?:[a-zA-Z0-9_-]*[a-zA-Z0-9_])?$/.test(formData.username)) {
      try {
        await signup(formData);
        navigate("/");
      } catch (err) {
        setFormErrors(err);
      }
    } else {
      setFormErrors(["Username must contain only letters, numbers, '-', and '_'. Also cannot start or end with '-'."]);
    }
  }

  /** Update form data field */
  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = evt.target;
    setFormData((data) => ({ ...data, [name]: value }));
  }

  return (
    <div className="SignupForm">
      {currentUser && <Navigate to="/" />}
      <div className="container col-md-6 offset-md-3">
        <h3 className="my-3">Sign Up</h3>
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <Label>Username</Label>
                <Input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label>Display Name</Label>
                <Input
                  type="text"
                  name="displayName"
                  value={formData.displayName}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label>Password</Label>
                <ShowHideInput
                  inputName="password"
                  inputValue={formData.password}
                  handleChange={handleChange}
                  required={true} />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label>Invitation Code</Label>
                <Input
                  type="text"
                  name="invitationCode"
                  value={formData.invitationCode}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              {formErrors.length ? (
                <Alert type="danger" messages={formErrors} />
              ) : null}

              <div className="d-grid">
                <Button className="text-dark" color="primary">
                  Sign Up
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <NavLink href="/login" className="mt-2">
          Have an account? Log In
        </NavLink>
      </div>
    </div>
  );
}

export default SignupPage;