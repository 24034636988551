import LinkCard from "../components/LinkCard";

function GroupList({ groups, numResults = null }) {

	if (numResults) groups = groups.slice(0, numResults);

	return (
		<div className="d-flex flex-column align-items-center">
			{groups.map((g) => <LinkCard type="group" info={g} key={g.id} />)}
		</div>

	);
}

export default GroupList;