import { faArrowRightLong, faMoneyBillTransfer, faPlus, faRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Row, Col } from "reactstrap";
import { formatCurrency } from "../utils/helpers";

/** LinkCard Component
 *
 * Props: listing {id, userId, photo, price, details}
 * State: None
 */

function PaymentCard({ payment, modifyPayment, disabled }) {
  return (

    <Card className="col-12 m-1 text-dark" color="light">
      <Button onClick={modifyPayment} disabled={disabled} color="light" className="text-dark">
        <CardBody className="p-0">
          <Row className="d-flex align-items-center">
            <Col className="d-flex flex-column text-start align-items-between">
              <CardSubtitle>{payment.payer.displayName}
              </CardSubtitle>
              <small>{payment.status === "Short" ? "" : `${payment.payer.handle ? "@" : ""}${payment.payer.handle || "(Invited)"}`}</small>
            </Col>
            <Col className="d-flex flex-column">
              <h6 className="m-0">${formatCurrency(payment.amount)}</h6>
              <FontAwesomeIcon icon={faArrowRightLong} />
              <small>{payment.status}</small>
            </Col>
            <Col className="d-flex flex-column text-end align-items-between">
              <CardSubtitle>
                {payment.payee.displayName}
              </CardSubtitle>
              <small>{`${payment.payee.handle ? "@" : ""}${payment.payee.handle || "(Invited)"}`}</small>

            </Col>
          </Row>
          <Row>


            {/* <Col className="d-flex justify-content-end align-items-end">
              <CardText><h6 className="m-0">${formatCurrency(payment.amount)}</h6></CardText>
            </Col> */}
            {/* <Col className="d-flex justify-content-end align-items-end">
              <CardText>{payment.status}</CardText>
            </Col> */}
          </Row>
        </CardBody>
      </Button>
    </Card>
  );
}

export default PaymentCard;
