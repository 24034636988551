/** Presentational component for showing bootstrap-style alerts.
 *
 * { LoginForm, SignupForm, ProfileForm } -> Alert
 **/

function NotFound() {

  return (
    <div>
      Sorry, the page you're looking for does not exist.
    </div>
  );
}

export default NotFound;
