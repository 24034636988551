import { useState } from "react";

import { Form, FormGroup, Label, Input, Button } from "reactstrap";

import TabbilyApi from "../api";
import Alert from "../common/Alert";


function InviteUserForm({ closeModal = null }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [alerts, setAlerts] = useState({
    type: "",
    messages: []
  });

  async function handleSubmit(evt) {
    evt.preventDefault();
    try {
      await TabbilyApi.inviteUser(name, email);
      setAlerts({type: "success", messages: ["User successfully invited!"]});
      setTimeout(() => closeModal(), 500);
    } catch (err) {
      setAlerts({type: "danger", messages: ["This user has already been invited."]});
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="name">
          Display Name
        </Label>
        <Input
          id="name"
          name="name"
          placeholder="Name"
          value={name}
          type="text"
          onChange={(evt) => setName(evt.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label for="email">
          Email
        </Label>
        <Input
          id="email"
          name="email"
          placeholder="Email"
          value={email}
          type="email"
          onChange={(evt) => setEmail(evt.target.value)}
          required
        />
      </FormGroup>
      {alerts.messages.length ? (
        <Alert type={alerts.type} messages={alerts.messages} />
      ) : null}
      <div className="d-flex flex-row justify-content-end">
        <Button type="submit" className="text-dark" color="primary">
          Invite User
        </Button>
      </div>
    </Form>
  );

}

export default InviteUserForm;