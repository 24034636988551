import { useState } from "react";

import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TabbilyApi from "../api";

function PaymentStatusForm({ currentPayment, isPayee = false, closeModal = null, refetchData = null }) {
  const [date, setDate] = useState(currentPayment.paymentDate ? new Date(currentPayment.paymentDate) : new Date(Date.now()));
  const [reconciled, setReconciled] = useState(currentPayment.reconciledDate);

  async function handleSubmit(evt) {
    evt.preventDefault();
    try {
      if (date) {
        await TabbilyApi.updatePayment(currentPayment.paymentId,
          (new Date(date)).toISOString(),
          reconciled ? (new Date(Date.now())).toISOString() : "");
      } else {
        await TabbilyApi.updatePayment(currentPayment.paymentId, "", "");
      }
      
      if (closeModal) closeModal();
      if (refetchData) refetchData();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <FormGroup className="d-flex flex-row justify-content-between align-items-center">
        <Label for="date" className="w-50">
          Payment Date
        </Label>
        <DatePicker
          id="date"
          name="date"
          selected={date}
          onChange={(date) => setDate(date)}
          isClearable
          className="form-control"
        />
      </FormGroup>
      <FormGroup className="d-flex flex-row justify-content-between align-items-start">
        <Label for="reconciled">
          Reconciled?
        </Label>
        <Input
          id="reconciled"
          name="reconciled"
          checked={reconciled}
          type="checkbox"
          onChange={() => setReconciled(!reconciled)}
          disabled={!isPayee}
        />
      </FormGroup>
      <div className="d-flex flex-row justify-content-end">
        <Button type="submit" className="text-dark" color="primary">
          Save Payment Status
        </Button>
      </div>
    </Form>
  );

}

export default PaymentStatusForm;