import { components } from "react-select";

export const Option = props => {
  return (
    <components.Option {...props}>
      <div>{props.data.label}</div>
      <div><small>{props.data.subLabel}</small></div>
    </components.Option>
  );
};

export const Theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral5: 'rgb(233,236,239)',
    neutral10: 'rgb(206,212,218)',
    neutral20: 'rgb(206,212,218)',
    neutral30: 'rgb(206,212,218)',
    neutral40: '#5e6472',
    neutral80: '#5e6472',
    primary25: 'rgba(184, 242, 230, 0.25)',
    primary50: 'rgba(184, 242, 230, 0.50)',
    primary75: '#b8f2e6',
    primary: '#b8f2e6',
  },
})