import LinkCard from "../components/LinkCard";

function EventList({ events, numResults = null, groupId = null }) {

	if (groupId) events = events.filter(e => +groupId === e.groupId);
	if (numResults) events = events.slice(0, numResults);

	return (
		<div className="d-flex flex-column align-items-center">
			{events.map((e) => <LinkCard type="event" info={e} key={e.id} />)}
		</div>

	);
}

export default EventList;