import React, { useState, useEffect, useCallback, useRef } from "react";

import { Form, FormGroup, Label, Input, Button, UncontrolledTooltip } from "reactstrap";
import Select from "react-select";
import { Option, Theme } from "../misc/Option";

import Alert from "../common/Alert";

import { SelectOptionInterface } from "../typing/interfaces";
import SplitContributionForm from "./SplitContributionForm";
import TabbilyApi from "../api";
import { findDifference, formatCurrency } from "../utils/helpers";
import "./expense.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faInfoCircle, faTrashCan } from "@fortawesome/free-solid-svg-icons";


export interface ItemInterface {
  itemId?: number,
  name: string,
  amount: number,
  dollar: string,
  splitBetween: any[],
}

function ItemForm({ idx, setParentFormData, prevItems, members, closeModal = null, handleRemoveItem }) {

  const initialFormRef = useRef(idx !== null ? {
    itemId: prevItems[idx].itemId,
    name: prevItems[idx].name,
    amount: prevItems[idx].amount,
    dollar: formatCurrency(prevItems[idx].amount),
    splitBetween: [],
  } : {
    name: "",
    amount: 0,
    dollar: "",
    splitBetween: [],
  });

  const [formData, setFormData] = useState<ItemInterface>(initialFormRef.current);

  const [participants, setParticipants] = useState(idx !== null ? prevItems[idx].splitBetween : null);

  /** Update form data field */

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = evt.currentTarget;

    if (name === "dollar") {
      setFormData((l) => ({ ...l, [name]: value.replace(/[^0-9,.]/, '') }));
    } else {
      setFormData((l) => ({ ...l, [name]: value }));
    }
  }

  function handleBlur(evt: React.ChangeEvent<HTMLInputElement>) {
    const amount = Number(evt.target.value.replace(/,/g, '')) * 100;
    const dollar = formatCurrency(amount);

    setFormData((l) => ({ ...l, amount, dollar }));
  }

  async function handleSubmit(evt) {
    evt.preventDefault();
    let items;
    if (idx !== null) {
      items = [...prevItems];
      items[idx] = { ...prevItems[idx], ...formData, splitBetween: participants };
    } else {
      items = [...prevItems, { ...formData, splitBetween: participants }];
    }
    setParentFormData(l => ({ ...l, items }));
    closeModal();
  }


  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label>
          Name
        </Label>
        <Input
          id="name"
          name="name"
          placeholder="Item Name"
          type="text"
          min="0"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label>
          Amount <span id="ItemAmount"><FontAwesomeIcon icon={faInfoCircle} className="info-icon" /></span>
        </Label>
        <UncontrolledTooltip
          placement="right"
          target="ItemAmount"
        >
          Item amount (excluding tax and tip)
        </UncontrolledTooltip>
        <div className="input-icon">
          <i>$</i>
          <Input
            id="dollar"
            name="dollar"
            placeholder="0.00"
            type="text"
            inputMode="decimal"
            min="0"
            value={formData.dollar}
            onBlur={handleBlur}
            onChange={handleChange}
            required
          />
        </div>
      </FormGroup>
      <Label>
        Shared by
      </Label>
      <Select
        id="selectMembers"
        options={members}
        value={participants}
        placeholder="Select participants"
        onChange={setParticipants}
        isMulti
        theme={Theme}
        components={{ Option }}
        isClearable={false}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        className="select-input"
        required
      />
      {(participants === null || participants.length === 0) &&
      <small className="text-dark mx-1">You must select at least one.</small>}
      <div className={idx !== null ? "d-flex justify-content-between mt-4" : "d-flex justify-content-end mt-4"}>
        {idx !== null && <Button onClick={() => {
          closeModal();
          setTimeout(() => handleRemoveItem(idx), 400);
        }}>
          <FontAwesomeIcon icon={faTrashCan} />
        </Button>}
        <Button type="submit" color="primary text-dark" disabled={participants === null || participants.length === 0}>
          <FontAwesomeIcon icon={faFloppyDisk} /> Save
        </Button>
      </div>
    </Form>
  );

}

export default ItemForm;