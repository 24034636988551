import { useState } from "react";

import { Form, FormGroup, Label, Input, Button, Card, CardBody } from "reactstrap";

import { useQuery } from "../utils/useQuery";
import TabbilyApi from "../api";

import Alert from "../common/Alert";

function UnsubscribeForm() {

  const query = useQuery();
  const [email, setEmail] = useState(query ? (decodeURIComponent(query.get("email") || "")) : "");
  const [alerts, setAlerts] = useState({
    type: "",
    messages: []
  });

  async function handleSubmit(evt) {
    evt.preventDefault();
    try {
      await TabbilyApi.unsubscribe(email);
      setAlerts({ type: "success", messages: ["You have unsubscribed successfully."] });
    } catch (err) {
      setAlerts({ type: "danger", messages: ["The server had an issue. Please try again later."] });
    }
  }

  return (
    <div className="container col-md-6 offset-md-3">
      <h3 className="my-3">Unsubscribe</h3>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="email">
                Email
              </Label>
              <Input
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                type="email"
                onChange={(evt) => setEmail(evt.target.value)}
                required
              />
            </FormGroup>
            <div className="d-grid">
              <Button type="submit" className="text-dark" color="primary">
                Unsubscribe
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      {alerts.messages.length ? (
        <Alert type={alerts.type} messages={alerts.messages} />
      ) : null}
    </div>
  );

}

export default UnsubscribeForm;