import { useNavigate } from "react-router-dom";
import GroupList from "../group/GroupList";
import EventList from "../event/EventList";
import { useModals } from "../utils/useModal";
import { useFetch } from "../utils/useFetch";
import CustomModal from "../components/CustomModal";
import LoadingSpinner from "../common/LoadingSpinner";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ServerError from "../common/ServerError";

function HomeLoggedIn() {
    const navigate = useNavigate();

    const [groupFormIsOpen, , toggleGroupForm] = useModals('group');
    const [eventFormIsOpen, , toggleEventForm] = useModals('event');

    const [groupsIsLoading, groups, groupsError] = useFetch("getAllGroups");
    const [eventsIsLoading, events, eventsError] = useFetch("getAllEvents");

    if (groupsError || eventsError) return <ServerError />;

    if (groupsIsLoading || eventsIsLoading) return <LoadingSpinner />;
    
    return (
        <>
            {!groupsIsLoading && !eventsIsLoading &&
                <>
                    <h4>Recent Groups</h4>
                    <GroupList groups={groups} numResults={4} />
                    <Button onClick={() => navigate("/groups")} className="text-dark" color="primary">See All Groups</Button>
                    <Button onClick={toggleGroupForm} className="btn m-3" color="primary" outline>
                        <FontAwesomeIcon icon={faPlus} /> New Group
                    </Button>
                    <CustomModal type="group" title="Create New Group" isOpen={groupFormIsOpen} toggle={toggleGroupForm} />
                    <h4 className="mt-3">Recent Events</h4>
                    <EventList events={events} numResults={4} />
                    <Button onClick={() => navigate("/events")} className="text-dark" color="primary">See All Events</Button>
                    <Button onClick={toggleEventForm} className="btn m-3" color="primary" outline>
                        <FontAwesomeIcon icon={faPlus} />  New Event
                    </Button>
                    <CustomModal type="event" title="Create New Event" isOpen={eventFormIsOpen} toggle={toggleEventForm} />
                </>}
        </>
    );
}

export default HomeLoggedIn;