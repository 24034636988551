import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import AddEditEventForm from "../event/AddEditEventForm";
import AddEditGroupForm from "../group/AddEditGroupForm";
import AddExpenseForm from "../expense/AddExpenseForm";
import InviteUserForm from "../invite/InviteUserForm";
import PaymentStatusForm from "../payment/PaymentStatusForm";

// import { Modal, Box } from "@mui/material";

interface CustomModalInterface {
  type: string,
  title: string,
  isOpen: any,
  toggle: any,
  edit?: boolean,
  data?: {
    currentName?: string,
    currentMembers?: null | { value: number, label: string, subLabel: string; }[],
    groupId?: string,
    group?: {
      value: string,
      label: string;
    },
    eventId?: string,
    paymentId?: string,
    paymentDate?: string,
    reconciledDate?: string,
  };
  closeModal?: any;
  refetchData?: any;
}
// interface CustomModalInterface {
//   type: string,
//   isOpen: boolean,
//   toggle: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>,
//   data?: {
//     currentName?: string,
//     currentMembers?: null | [],
//     groupId?: string,
//     group?: {
//       value: string,
//       label: string;
//     };

//   };
// }


function CustomModal({ type, title, isOpen, toggle, edit = false, data, closeModal, refetchData }: CustomModalInterface) {

  const form = {
    group: <AddEditGroupForm
      currentName={data?.currentName}
      currentMembers={data?.currentMembers}
      groupId={data?.groupId}
      closeModal={closeModal}
      refetchData={refetchData}
    />,
    event: <AddEditEventForm
      currentName={data?.currentName}
      group={data?.group}
      eventId={data?.eventId}
      closeModal={closeModal}
      refetchData={refetchData}
    />,
    invite: <InviteUserForm closeModal={closeModal} />
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle} className="bg-light align-items-center">
          {title}
          </ModalHeader>
          <ModalBody>
          {form[type]}
          </ModalBody>
      </Modal>
    </>
  );
}

export default CustomModal;