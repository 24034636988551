import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Form, FormGroup, Label, Input, Card, CardBody } from "reactstrap";

import Alert from "../common/Alert";
import ShowHideInput from "../components/ShowHideInput";

import TabbilyApi from "../api";

/** Signup form.
 *
 * Shows form and manages update to state on changes.
 * On submission:
 * - calls signup function prop
 *
 * Props: signup() to call in App
 * State: formData, formErrors
 *
 * Routes -> SignupPage -> Alert
 * Routed as /signup
 */

function ChangePassword({ token }) {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [alerts, setAlerts] = useState({
    type: "",
    messages: []
  });

  /** Handle form submit:
   *
   * Calls login func prop and, if not successful, sets errors.
   */

  async function handleSubmit(evt) {
    evt.preventDefault();
    try {
      await TabbilyApi.changePassword(token, newPassword);
      setAlerts({type: "success", messages: ["Password changed successfully! Redirecting to login page..."]});
      setTimeout(() => navigate("/login"), 2000);
    } catch (err) {
      setAlerts({type: "danger", messages: err});
    }
  }

  return (
    <div className="SignupForm">
      <div className="container col-md-6 offset-md-3">
        <h3 className="my-3">Set New Password</h3>
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <Label>New Password</Label>
                <ShowHideInput
                  inputName="newPassword"
                  inputValue={newPassword}
                  handleChange={(evt) => setNewPassword(evt.target.value)}
                  required={true} />
              </FormGroup>
              {alerts.messages.length ? (
                <Alert type={alerts.type} messages={alerts.messages} />
              ) : null}
              <div className="d-grid">
                <Button className="text-dark" color="primary">
                  Change Password
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ChangePassword;