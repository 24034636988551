import { useNavigate } from "react-router-dom";
import GroupList from "../group/GroupList";
import EventList from "../event/EventList";
import CustomModal from "../components/CustomModal";
import LoadingSpinner from "../common/LoadingSpinner";
import { Button } from "reactstrap";

function HomeLoggedOut() {
  const navigate = useNavigate();

  return (
    <>
      <Button onClick={() => navigate("/login")} className="text-dark my-5 mx-2" color="info">Log In</Button>
      <Button onClick={() => navigate("/signup")} className="text-dark" color="primary">Sign Up</Button>

    </>
  );
}

export default HomeLoggedOut;