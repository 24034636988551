import { Button, Row, Col, Card, CardBody, CardTitle, NavLink } from "reactstrap";

import { formatCurrency } from "../utils/helpers";
import "./expense.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function ItemizedExpense({ toggle, items, setCurrentItem, handleRemoveItem, editing = true }) {

  // function handleRemoveItem(idx: number) {
  //   const newItems = [...items];
  //   newItems.splice(idx, 1);
  //   setFormData(l => ({ ...l, items: newItems }));
  // }

  return (
    <fieldset disabled={!editing}>
      <>
        <h6>Items ({items.length})</h6>
        {(items.length > 0) &&
          <div >
            {items.map((i, idx) => (
              <Card key={`item-${i.name}`} className="my-1 expense-card" color="light">
                <Button onClick={() => {
                  setCurrentItem(idx);
                  toggle();
                }} color="light" className="text-dark p-0 m-0">
                  <CardBody className="px-2 py-1">
                    <Row>
                      <Col className="text-start">
                        <CardTitle className="m-0">
                          {i.name}
                        </CardTitle>
                        <small className="m-0">
                          {i.splitBetween.map(u => u.label).join(", ")} {i.splitBetween.length <= 1 ? "got this" : "shared this"}
                        </small>
                      </Col>
                      <Col className="col-3 d-flex flex-column align-items-end justify-content-between">
                        <h6 className="m-0">${formatCurrency(i.amount)}</h6>
                      </Col>
                    </Row>
                  </CardBody>
                </Button>
                {editing && <div>
                  <Button
                    onClick={() => handleRemoveItem(idx)} className="mx-2 small-delete small-delete-corner" size="sm">
                    <FontAwesomeIcon icon={faTrashCan} />
                  </Button>
                </div>}
              </Card>
            ))}
          </div>
        }
      </>
      <div className="d-flex mt-2">
        {editing && <Button onClick={() => {
          setCurrentItem(null);
          toggle();
        }}
          color="primary" className="text-dark col-12" outline><FontAwesomeIcon icon={faPlus} /> New Item</Button>}
      </div>

    </fieldset>
  );
}

export default ItemizedExpense;