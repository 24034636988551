import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";

import { AppFunctions } from "../typing/interfaces";
import { useQuery } from "../utils/useQuery";
import userContext from "../UserContext";
import ServerError from "../common/ServerError";

/** ProfilePage Component
 *
 * Props:
 * -login() to call in App
 *
 * State:
 * -formData, formErrors
 */

function ForgotPassword() {
  const { currentUser } = useContext(userContext);
  const token = useQuery();

  if (currentUser) return <Navigate to="/" />;

  return (
    <div className="ForgotPassword">
      {!token.get("token") && <ResetPassword />}
      {token.get("token") && <ChangePassword token={token.get("token")} />}
    </div>
  );
}

export default ForgotPassword;