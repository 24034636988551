import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import userContext from "../UserContext";
import { Button } from "reactstrap";

import GroupList from "../group/GroupList";
import EventList from "../event/EventList";
import { useModals } from "../utils/useModal";
import { useFetch } from "../utils/useFetch";
import CustomModal from "../components/CustomModal";
import LoadingSpinner from "../common/LoadingSpinner";

import HomeLoggedIn from "./HomeLoggedIn";
import HomeLoggedOut from "./HomeLoggedOut"

function Homepage() {

    const { currentUser } = useContext(userContext);
    const navigate = useNavigate();

    // const [groupFormIsOpen, setGroupFormIsOpen, toggleGroupForm] = useModals('group');
    // const [eventFormIsOpen, setEventFormIsOpen, toggleEventForm] = useModals('event');

    // const [groupsIsLoading, groups, groupsError ] = useFetch("getAllGroups");
    // const [eventsIsLoading, events, eventsError ] = useFetch("getAllEvents");


    // if (currentUser) return <LoadingSpinner />;
    return (
        <>
            {!currentUser && <HomeLoggedOut />}
            {currentUser &&
            <>
                {/* <div>Recent Groups</div>
                <GroupList groups={groups} numResults={4} />
                <Button onClick={() => navigate("/groups")} className="btn btn-primary">See All Groups</Button>
                <CustomModal type="group" title="Create New Group" isOpen={groupFormIsOpen} toggle={toggleGroupForm} />
                <div>Recent Events</div>
                <EventList events={events} numResults={4} />
                <Button onClick={() => navigate("/events")} className="btn btn-primary">See All Events</Button>
                <CustomModal type="event" title="Create New Event" isOpen={eventFormIsOpen} toggle={toggleEventForm} /> */}
                <HomeLoggedIn />
            </>}
        </>
    );
}

export default Homepage;