import React from "react";
import { UncontrolledAlert } from "reactstrap";

/** Presentational component for showing bootstrap-style alerts.
 *
 * { LoginForm, SignupForm, ProfileForm } -> Alert
 **/

function Alert({ type = "info", messages = [] }) {
  return (
    <UncontrolledAlert color={type} className="mt-2 text-center">
      {messages.map(error => (
        <p className="mb-0 small" key={error}>
          {error}
        </p>
      ))}
    </UncontrolledAlert>
  );
}

export default Alert;
