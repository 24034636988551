import React from "react";

interface ContextInterface {
  currentUser: {
    userId: string | null,
    displayName: string | null
  }
}

const UserContext = React.createContext<ContextInterface | null>(null);

export default UserContext;