import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


function Confirmation({ isOpen, toggle, confirm = null, cancel = null, message = "Are you sure you want to?" }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="bg-light">
        Confirm
      </ModalHeader>
      <ModalBody>
        {message}
      </ModalBody>
      <ModalFooter>
        <Button onClick={confirm}>
          Confirm
        </Button>
        <Button onClick={cancel} color="primary" className="text-dark">
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default Confirmation;