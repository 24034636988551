/** Presentational component for showing bootstrap-style alerts.
 *
 * { LoginForm, SignupForm, ProfileForm } -> Alert
 **/

function ServerError() {

  return (
    <div>
      Sorry, the server appears to be down. Please try again later.
    </div>
  );
}

export default ServerError;
