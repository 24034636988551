import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input, Card, CardBody } from "reactstrap";


import { EditUserInterface } from "../typing/interfaces";
import Alert from "../common/Alert";
import ShowHideInput from "../components/ShowHideInput";

/** ProfilePage Component
 *
 * Props:
 * -login() to call in App
 *
 * State:
 * -formData, formErrors
 */

function EditProfileForm({ user, updateUser }) {

  const [formData, setFormData] = useState<EditUserInterface>({
    username: user.handle,
    displayName: user.displayName,
    email: user.email,
    password: "",
    newPassword: "",
  });
  const [alerts, setAlerts] = useState({
    type: "",
    messages: []
  });


  /** Handle form submit:
   *
   * Calls login func prop and, if not successful, sets errors.
   */

  async function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    if ((formData.username !== user.handle || formData.email !== user.email || formData.newPassword !== "") && formData.password === "") {
      setAlerts({ type: "danger", messages: ["Password is required to make changes."] });
    } else {
      try {
        await updateUser(formData);
        setAlerts({ type: "success", messages: ["Profile successfully changed."] });
      } catch (err) {
        setAlerts({ type: "danger", messages: err });
      }
    }
  }

  /** Update form data field */

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = evt.currentTarget;
    setFormData((l) => ({ ...l, [name]: value }));
  }

  return (
    <div className="container col-md-6 offset-md-3">
      <h3 className="my-3">Edit Profile</h3>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <Label>Username</Label>
              <Input
                name="username"
                className="form-control"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label>Display Name</Label>
              <Input
                name="displayName"
                className="form-control"
                value={formData.displayName}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label>Email</Label>
              <Input
                type="email"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label>Password</Label>
              <ShowHideInput
                inputName="password"
                inputValue={formData.password}
                handleChange={handleChange} />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label>New Password</Label>
              <ShowHideInput
                inputName="newPassword"
                inputValue={formData.newPassword}
                handleChange={handleChange} />
            </FormGroup>
            <div className="d-grid">
              <Button className="text-dark" color="primary">
                Save Profile
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      {alerts.messages.length ? (
        <Alert type={alerts.type} messages={alerts.messages} />
      ) : null}
    </div>
  );
}

export default EditProfileForm;