import React, { useState, useEffect } from 'react';
import { BrowserRouter} from 'react-router-dom';
import './App.css';
import RoutesList from './RoutesList';
import Footer from './common/Footer';

import { EditUserInterface, LoginInterface, SignupInterface } from './typing/interfaces';
import UserContext from './UserContext';
import { useFetch } from './utils/useFetch';

import LoadingSpinner from './common/LoadingSpinner';
import NavBar from './Navbar';

import TabbilyApi from './api';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  // const [userIsLoading, currentUser, userError] = useFetch(token ? "getCurrentUser" : null);

  // Load user info from API. Until a user is logged in and they have a token,
  // this should not run. It only needs to re-run when a user logs out, so
  // the value of the token is a dependency for this effect.

  useEffect(
    function loadUserInfo() {
      async function getCurrentUser() {
        if (!currentUser) {
          try {
            const currentUser = await TabbilyApi.getCurrentUser();
            setCurrentUser(currentUser);
            setIsLoading(false);
          } catch (err) {
            setCurrentUser(null);
            setIsLoading(false);
          }
        }
      }

      getCurrentUser();
    }
  );
  /** Handles site-wide signup.
 *
 * Automatically logs them in (set token) upon signup.
 *
 * Make sure you await this function to see if any error happens.
 */

  async function signup(signupData: SignupInterface) {
    await TabbilyApi.signup(signupData);
  }

  /** Handles site-wide login.
   *
   * Logs in a user
   *
   * Make sure you await this function to see if any error happens.
   */

  async function login(loginData: LoginInterface) {
    await TabbilyApi.login(loginData);
    window.location.href = "/";
  }

  async function updateUser(updateData: EditUserInterface) {
    await TabbilyApi.updateUser(updateData);
  }

  async function logout() {
    await TabbilyApi.logout();
    setCurrentUser(null);
    window.location.href = '/';
  }

  // if (isLoading) return <LoadingSpinner />;


  return (
    <>
      {!isLoading &&
        <UserContext.Provider value={{ currentUser }}>
          <div className="App">
            <NavBar logout={logout} />
            <BrowserRouter>
              <RoutesList login={login} signup={signup} updateUser={updateUser} />
            </BrowserRouter>
            <Footer />
          </div>
        </UserContext.Provider>
      }
    </>
  );
}

export default App;
