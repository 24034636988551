import { useState } from "react";

function useModals(modalName = '') {
  const [modalOpen, setModalOpen] = useState({
    [modalName]: false,
  });

  const toggle = () =>
    setModalOpen({
      ...modalOpen,
      [modalName]: !modalOpen[modalName],
    });

  const closeModal = () =>
    setModalOpen({
      ...modalOpen,
      [modalName]: false
    });

  return [modalOpen[modalName], closeModal, toggle] as const;
};

// function useModals(modalName = '') {
//   const [modalOpen, setModalOpen] = useState(false);

//   const toggle = () =>
//     setModalOpen(!modalOpen);

//   const closeModal = () =>
//     setModalOpen(false);

//   return [modalOpen, closeModal, toggle] as const;
// };

export { useModals };