import { useState } from "react";
import { Input, Button } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./showHideInput.css";

/** LinkCard Component
 *
 * Props: listing {id, userId, photo, price, details}
 * State: None
 */

function ShowHideInput({ inputName, inputValue, handleChange, required = false }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="show-hide-input">
      <Input
        type={showPassword ? "text" : "password"}
        name={inputName}
        value={inputValue}
        onChange={handleChange}
        required={required}
      />
      <Button onClick={() => setShowPassword(!showPassword)}><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} /></Button>
    </div>
  );
}

export default ShowHideInput;
