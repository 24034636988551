import { useState, useEffect, useCallback } from "react";
import TabbilyApi from "../api";

function useFetch (method, input = null) {
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState(null);
  const [serverError, setServerError] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      if (!method) return;
      
      const resp = input ? await TabbilyApi[method](input) : await TabbilyApi[method]();
      setApiData(resp);
      setIsLoading(false);
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  }, [method, input])

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [ isLoading, apiData, serverError, fetchData ] as const;
};

export { useFetch };