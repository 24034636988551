import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import EditProfileForm from "./EditProfileForm";

import { AppFunctions } from "../typing/interfaces";
import { useFetch } from "../utils/useFetch";
import userContext from "../UserContext";
import ServerError from "../common/ServerError";
import LoadingSpinner from "../common/LoadingSpinner";

/** ProfilePage Component
 *
 * Props:
 * -login() to call in App
 *
 * State:
 * -formData, formErrors
 */

function ProfilePage({ updateUser }: Pick<AppFunctions, "updateUser">) {
  const { currentUser } = useContext(userContext);
  const [userIsLoading, user, userError] = useFetch("getCurrentUser");


  if (!currentUser) return <Navigate to="/" />;

  if (userError) return <ServerError />;

  if (userIsLoading) return <LoadingSpinner />;

  return (
    <div className="ProfilePage">
      {!userIsLoading && <EditProfileForm user={user} updateUser={updateUser} />}
    </div>
  );
}

export default ProfilePage;