import { useContext } from "react";
import { Nav, NavItem, NavLink, NavbarBrand, Navbar, Button } from "reactstrap";
import { useModals } from "./utils/useModal";
import CustomModal from "./components/CustomModal";

import userContext from "./UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faUserPlus, faTrashCan, faCalendarCheck, faUser, faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";

/** Navbar Component
 *
 * Props: logout() to call in App
 * State: none
 */

function NavBar({ logout }) {
  const { currentUser } = useContext(userContext);

  const [inviteFormIsOpen, closeInviteFormModal, toggleInviteForm] = useModals('event');

  return (
    <Navbar className="sticky-top mb-4" color="dark">
      <NavbarBrand href="/" className="brand text-secondary" >tabbily.</NavbarBrand>
      <Nav className="Navbar">
        {currentUser && (
          <>
            <NavItem>
              <NavLink role="button" onClick={toggleInviteForm}><FontAwesomeIcon icon={faUserPlus} /></NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/groups" aria-label="Groups"><FontAwesomeIcon icon={faUsers} /></NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/events"><FontAwesomeIcon icon={faCalendarCheck} /></NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/profile"><FontAwesomeIcon icon={faUser} /></NavLink>
            </NavItem>
            <NavItem>
              <NavLink role="button" onClick={logout}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
              </NavLink>
            </NavItem>
            <CustomModal
              type="invite"
              title="Invite Member"
              isOpen={inviteFormIsOpen}
              toggle={toggleInviteForm}
              closeModal={closeInviteFormModal}
            />
          </>
        )}
      </Nav>
    </Navbar>
  );
}

export default NavBar;
